import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "components/common/Dropdown";
import ItemLightbox from "components/Backend/DocumentContainer/ItemLightbox";
import ConfirmLightbox from "components/common/ConfirmLightbox";
import * as DocumentActions from "actions/Document";
import { changeOverlayContent } from "events/OverlayEvents";
import { MY_SYNCHRONE_URL as basePath } from "config/Url.js";
import { refreshToken } from "actions/Common";
import { needTokenRefresh } from "utils/Utils";
import "./FileRow.scss";

const FileRow = (props) => {
    const { categoryId, file, visible, nested, isEdit, isInFolder, type } =
        props;
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div
            onClick={() => {
                if (!isEdit) {
                    if (needTokenRefresh()) {
                        refreshToken().then(() => {
                            window.open(file.downloadUrl);
                        });
                    } else {
                        window.open(file.downloadUrl);
                    }
                }
            }}
            elementid={file?.id}
            className={
                "file-row movable-item line-container" +
                (hovered ? " hovered" : "") +
                (!visible ? " hidden" : "") +
                (nested ? " nested" : "")
            }
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            target="_blank"
            rel="noopener noreferrer"
            title={file?.label}
        >
            <i className="far fa-file-alt"></i>
            <div className="file-row-label">{file?.label}</div>
            {hovered && !isEdit && (
                <i className="fal fa-arrow-from-bottom download-icon"></i>
            )}
            {isEdit && (
                <Dropdown
                    isDropdownVisible={isDropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                >
                    <ul className="dropdown-list">
                        <li
                            onClick={() => {
                                setDropdownVisible(false);
                                changeOverlayContent(
                                    <ItemLightbox
                                        fileId={file?.id}
                                        mode="update"
                                        type={type}
                                        itemLabel={file.label}
                                    />
                                );
                            }}
                        >
                            Renommer le fichier
                        </li>
                        <li
                            onClick={() => {
                                setDropdownVisible(false);
                                changeOverlayContent(
                                    <ConfirmLightbox
                                        title={"Supprimer un fichier"}
                                        message={`Êtes-vous sûr de vouloir supprimer le fichier "${file.label}" ?`}
                                        handleSubmit={() =>
                                            dispatch(
                                                DocumentActions.deleteFile(
                                                    file.id,
                                                    type
                                                )
                                            ).then(() => {
                                                changeOverlayContent(null);
                                                dispatch(
                                                    DocumentActions.fetchDocuments()
                                                );
                                                dispatch(
                                                    DocumentActions.fetchUnions()
                                                );
                                            })
                                        }
                                    />
                                );
                            }}
                        >
                            Supprimer le fichier
                        </li>
                    </ul>
                </Dropdown>
            )}
        </div>
    );
};

export default FileRow;
