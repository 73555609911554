import React from "react";
import * as UserActions from "actions/User";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setAppLoading } from "actions/Common";

import "./RunAsUser.scss";

const RunAsUser = (props) => {
    const me = useSelector((state) => state.user.me);
    const dispatch = useDispatch();

    return (
        <Link
            to="/"
            onClick={() => props.handleClick(props.user.username)}
            className="run-as-user"
        >
            {props.user.identity}
        </Link>
    );
};

export default RunAsUser;
