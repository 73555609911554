import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import "./Intervention.scss";
import * as TimesheetActions from "actions/Timesheet";
import DeleteOvertime from "components/TimesheetContainer/Forms/Overtime/DeleteOvertime";
import { changeOverlayContent } from "events/OverlayEvents";

const Intervention = (props) => {
    const HOURS_NOT_WORKED_TRANSPARENT_COLOR = "#FEDE99";
    const {
        entity,
        currentMonth,
        currentYear,
        firstDayOfMonth,
        hovered,
        isMonthEditable,
        expandedDisplay,
        type,
    } = props;
    const dispatch = useDispatch();
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );

    const getLeftPosition = () => {
        if (expandedDisplay) {
            return "initial";
        }

        return (
            moment.parseZone(entity.date).weekday() * 171 +
            2 +
            (props.entity.startMoment === "pm" ? 169 / 2 : 0) +
            "px"
        );
    };

    const getTopPosition = () => {
        if (expandedDisplay) {
            return "initial";
        }

        return (
            Math.ceil(
                (moment.parseZone(entity.date).date() + firstDayOfMonth.weekday()) / 7 - 1
            ) *
                122 +
            60 +
            "px"
        );
    };

    const containerStyles = {
        position: "absolute",
        left: getLeftPosition(),
        top: getTopPosition(),
        width: "169px",
    };
    const bulletStyles = {};
    if (hovered) {
        bulletStyles.borderColor = "transparent";
        bulletStyles.backgroundColor = HOURS_NOT_WORKED_TRANSPARENT_COLOR;
        bulletStyles.cursor = "pointer";
    }

    const hoverOvertime = (isHovered) => {
        if (type !== "view") {
            if (entity && !selectedStartDay) {
                if (
                    entity.id &&
                    entity.state !== "validated1" &&
                    entity.state !== "validated2"
                ) {
                    if (isHovered) {
                        dispatch(TimesheetActions.hoverOvertime(entity.id));
                    } else {
                        dispatch(TimesheetActions.hoverOvertime(null));
                    }
                }
            }
        }
    };

    const handleClick = () => {
        if (type !== "view") {
            if (isMonthEditable) {
                changeOverlayContent(
                    <DeleteOvertime
                        entity={entity}
                        overtimeCode={entity.overtimeCode}
                        currentMonth={currentMonth}
                        currentYear={currentYear}
                    />
                );
            }
        }
    };

    return (
        <div
            className={"hour-container" + (type === "view" ? " view-only" : "")}
            style={!expandedDisplay ? containerStyles : {}}
            onMouseEnter={() => hoverOvertime(true)}
            onMouseLeave={() => hoverOvertime(false)}
            onClick={() => handleClick()}
            title={entity.description}
        >
            <div className="hour-bullet" style={bulletStyles}>
                &nbsp;
            </div>
            <div className="hour-label">
                <div className="hour-times">
                    {entity.start} - {entity.end}
                </div>
                <div className="hour-description">{entity.description}</div>
            </div>
        </div>
    );
};

export default Intervention;
