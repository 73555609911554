import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import moment from "moment";
import "moment/locale/fr";

import * as TimesheetActions from "actions/Timesheet";
import CalendarHeaderDate from "components/TimesheetContainer/Calendar/CalendarHeaderDate";
import "./CalendarHeader.scss";

const CalendarHeader = (props) => {
    const dispatch = useDispatch();
    const { monthEntity, type, user } = props;
    const [hovered, setHovered] = useState(false);

    const me = useSelector((state) => state.user.me);
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const firstDayOfMonth = moment(
        `01-${String(monthEntity.month).padStart(2, "0")}-${monthEntity.year}`,
        "DD-MM-YYYY"
    );
    const lastDayOfMonth = moment(
        `01-${String(monthEntity.month).padStart(2, "0")}-${monthEntity.year}`,
        "DD-MM-YYYY"
    ).endOf("month");
    const missionsSelector = createSelector(
        (state) => state.timesheet.entities.eventCodes,
        (eventCodes) =>
            Object.values(eventCodes).filter(
                (eventCode) =>
                    eventCode.type === "activity" &&
                    eventCode.shortCode === "PR" &&
                    eventCode.startAt &&
                    eventCode.endAt &&
                    ((moment(eventCode.startAt).format("YYYY-MM-DD") <=
                        firstDayOfMonth.format("YYYY-MM-DD") &&
                        moment(eventCode.endAt).format("YYYY-MM-DD") >=
                            firstDayOfMonth.format("YYYY-MM-DD")) ||
                        (moment(eventCode.startAt).format("YYYY-MM-DD") >=
                            firstDayOfMonth.format("YYYY-MM-DD") &&
                            moment(eventCode.endAt).format("YYYY-MM-DD") <=
                                lastDayOfMonth.format("YYYY-MM-DD")) ||
                        (moment(eventCode.startAt).format("YYYY-MM-DD") >=
                            firstDayOfMonth.format("YYYY-MM-DD") &&
                            moment(eventCode.startAt).format("YYYY-MM-DD") <=
                                lastDayOfMonth.format("YYYY-MM-DD") &&
                            moment(eventCode.endAt).format("YYYY-MM-DD") >=
                                lastDayOfMonth.format("YYYY-MM-DD")) ||
                        (moment(eventCode.startAt).format("YYYY-MM-DD") <=
                            firstDayOfMonth.format("YYYY-MM-DD") &&
                            moment(eventCode.endAt).format("YYYY-MM-DD") >=
                                firstDayOfMonth.format("YYYY-MM-DD") &&
                            moment(eventCode.endAt).format("YYYY-MM-DD") <=
                                lastDayOfMonth.format("YYYY-MM-DD")))
            )
    );
    const missionsForMonth = useSelector(missionsSelector);
    const lowerLimit =
        moment(me.seniorityAt) > moment("01-01-2019", "DD-MM-YYYY")
            ? moment(me.seniorityAt)
            : moment("01-01-2019", "DD-MM-YYYY");

    const upperLimit =
        me && me.endAt ? moment(me.endAt) : moment().add("11", "month");

    const changeMonth = (movement) => {
        const targetMonth = moment(
            `01-${monthEntity.month}-${monthEntity.year}`,
            "DD-MM-YYYY"
        );

        if (movement === "previous") {
            targetMonth.subtract(1, "months");
        } else if (movement === "next") {
            targetMonth.add(1, "months");
        }

        return `/timesheet/${targetMonth.month() + 1}/${targetMonth.year()}`;
    };

    const clearCalendar = () => {
        dispatch(TimesheetActions.clearCalendar());
    };

    const isAllowedPreviousMonth = () => {
        if (
            moment(
                `01-${monthEntity.month}-${monthEntity.year}`,
                "DD-MM-YYYY"
            ).subtract(1, "months") < moment(lowerLimit).startOf("month")
        ) {
            return false;
        }

        return true;
    };

    const isAllowedNextMonth = () => {
        if (
            moment(
                `01-${monthEntity.month}-${monthEntity.year}`,
                "DD-MM-YYYY"
            ) >= upperLimit.startOf("month")
        ) {
            return false;
        }

        return true;
    };

    const hasMultipleMissions = () => {
        return missionsForMonth.length > 1;
    };

    const toggleMissions = () => {
        setHovered(!hovered);
    };

    return (
        <div className="calendar-header">
            <span
                style={
                    hasMultipleMissions() ? { width: "96%" } : { width: "100%" }
                }
                className={
                    (monthEntity.submitted ? "submitted " : "") +
                    (type === "view" ? " view " : "") +
                    "month-header"
                }
            >
                {isAllowedPreviousMonth() &&
                !selectedStartDay &&
                type !== "view" &&
                monthEntity.month ? (
                    <Link to={changeMonth("previous")} onClick={clearCalendar}>
                        <i className="left-header far fa-chevron-left"></i>
                    </Link>
                ) : null}

                <CalendarHeaderDate
                    monthEntity={monthEntity}
                    type={type}
                    user={user}
                />

                {isAllowedNextMonth() &&
                !selectedStartDay &&
                type !== "view" &&
                monthEntity.month ? (
                    <Link to={changeMonth("next")} onClick={clearCalendar}>
                        <i className="right-header far fa-chevron-right"></i>
                    </Link>
                ) : null}
            </span>
            {hasMultipleMissions() && (
                <span className="multiple-missions-indicator-container">
                    <i
                        onMouseEnter={toggleMissions}
                        onMouseLeave={toggleMissions}
                        className="fal fa-info-square multiple-missions-indicator"
                    ></i>
                    <div
                        className={
                            (hovered ? "" : "hidden ") +
                            "multiple-missions-details"
                        }
                    >
                        <div className="multiple-missions-title">
                            VOS AFFECTATIONS (mois en cours) :
                        </div>
                        {missionsForMonth.map((mission, key) => {
                            let startAt =
                                moment(mission.startAt).format("YYYY-MM-DD") <
                                firstDayOfMonth.format("YYYY-MM-DD")
                                    ? firstDayOfMonth.format("DD/MM")
                                    : moment(mission.startAt).format("DD/MM");
                            let endAt =
                                moment(mission.endAt).format("YYYY-MM-DD") >
                                lastDayOfMonth.format("YYYY-MM-DD")
                                    ? lastDayOfMonth.format("DD/MM")
                                    : moment(mission.endAt).format("DD/MM");
                            return (
                                <div className="mission-label" key={key}>
                                    <span className="mission-dates">
                                        • du {startAt} au {endAt}
                                    </span>
                                    <span className="mission-description">
                                        {mission.description}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </span>
            )}
        </div>
    );
};

export default CalendarHeader;
