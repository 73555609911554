import React, { useState } from "react";
import "./HarassmentItem.scss";

const HarassmentItem = (props) => {
    const { item } = props;

    return (
        <div className="harassment-item">
            {Object.values(item.contacts).map((contact, key) => {
                return (
                    <div className="contact-content" key={key}>
                        <span className="contact-name">{contact.name}</span>
                        <a
                            className="contact-email"
                            href={`mailto:${contact.email}`}
                        >
                            <i className="fal fa-envelope-open"></i>
                            {contact.email}
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export default HarassmentItem;
