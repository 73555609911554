import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";

import "./LinkedinWidget.scss";

const LinkedinWidget = (props) => {
    const post = props.post;

    return (
        <div className="container-linkedin">
            <div className="content-center">
                <div className="link-linkedin">
                    <img
                        className="synchrone-img"
                        src="img/synchrone.png"
                        alt="synchrone_image"
                    />
                    <div className="link-linkedin-synchrone">
                        <span className="span-synchrone">
                            <a
                                href="https://www.linkedin.com/company/synchrone-fr/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Synchrone Fr
                            </a>
                        </span>
                        <span className="span-synchrone-fr">
                            le{" "}
                            {moment
                                .parseZone(post.publishedAt)
                                .format("DD/MM/YYYY")}
                        </span>
                    </div>
                    <div className="linkedin-logo">
                        <a
                            href={post.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <svg
                                id="linkedin-inbug-color-icon"
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                                x="0"
                                y="0"
                                preserveAspectRatio="xMinYMin meet"
                                className="artdeco-icon"
                            >
                                <g
                                    className="large-icon"
                                    style={{ fill: "currentColor" }}
                                    id="linkedin-inbug-color-icon-large"
                                >
                                    <path
                                        d="M20.12,2H3.88A1.88,1.88,0,0,0,2,3.88V20.12A1.88,1.88,0,0,0,3.88,22H20.12A1.88,1.88,0,0,0,22,20.12V3.88A1.88,1.88,0,0,0,20.12,2Z"
                                        style={{ fill: "#0073b1" }}
                                    ></path>
                                    <rect
                                        x="5"
                                        y="10"
                                        width="3"
                                        height="9"
                                        style={{ fill: "#fff" }}
                                    ></rect>
                                    <path
                                        d="M6.5,4.69A1.81,1.81,0,1,0,8.31,6.5,1.82,1.82,0,0,0,6.5,4.69Z"
                                        style={{ fill: "#fff" }}
                                    ></path>
                                    <path
                                        d="M15.55,9.88a3.4,3.4,0,0,0-2.76,1.24h0V10H10v9h3V15c0-1.39.48-2.12,1.55-2.12.81,0,1.45.43,1.45,1.87V19h3V13.75C19,11.14,17.81,9.88,15.55,9.88Z"
                                        style={{ fill: "#fff" }}
                                    ></path>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div
                    className="content-linkedin"
                    dangerouslySetInnerHTML={{
                        __html: post.homepageContent,
                    }}
                ></div>
            </div>
            <div className="img-linkedin">
                {post.homepageImageUrl && (
                    <a
                        href={post.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={post.homepagePicture} alt={post.title} />
                    </a>
                )}
            </div>
        </div>
    );
};

export default LinkedinWidget;
