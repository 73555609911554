import React from "react";
import moment from "moment";
import "moment/locale/fr";
import Lightbox from "components/common/Lightbox";
import { useDispatch } from "react-redux";
import * as TimesheetActions from "actions/Timesheet";
import { changeOverlayContent } from "events/OverlayEvents";

import "./DeleteOvertime.scss";
import { daysForOvertimeList } from "utils/Utils";

const DeleteOvertime = (props) => {
    const { entity, overtimeCode, currentMonth, currentYear } = props;
    const dispatch = useDispatch();

    const handleSubmit = () => {
        dispatch(TimesheetActions.deleteOvertime(entity.id)).then(() => {
            changeOverlayContent(null);
            dispatch(
                TimesheetActions.fetchEntireMonth(currentMonth, currentYear)
            );
        });
    };

    const HourContainer = (props) => {
        const { day } = props;

        const HourLine = (props) => {
            const { hour } = props;

            return (
                <div>
                    {hour.start}{" "}
                    <div className="hour-separator">
                        <i className="far fa-chevron-right"></i>
                    </div>{" "}
                    {hour.end}
                </div>
            );
        };

        return (
            <div className="overtime-delete-day-container">
                <div className="overtime-delete-date">
                    {moment.parseZone(day.date).format("dddd D MMMM")}
                </div>
                <div className="overtime-delete-hour-container">
                    {day.hours.map((hour, key) => {
                        return <HourLine key={key} hour={hour} />;
                    })}
                </div>
            </div>
        );
    };

    const TicketLine = (props) => {
        const { day } = props;

        return (
            <div className="overtime-delete-ticket-line">
                <div className="overtime-delete-date">
                    {moment.parseZone(day.date).format("dddd D MMMM")}
                </div>
                <div className="overtime-ticket-amount">{day.tickets}</div>
            </div>
        );
    };

    const RecapHeader = (props) => {
        const HourHeader = () => {
            return (
                <div className="overtime-days-container-header">
                    <div className="col1">Début :</div>
                    <div className="col2">Fin :</div>
                </div>
            );
        };

        const TicketHeader = () => {
            return (
                <div className="overtime-days-container-header">
                    <div className="tickets-header">Nombre de tickets :</div>
                </div>
            );
        };

        return (
            <div>
                {entity.hourCount > 0 && <HourHeader />}
                {entity.ticketCount > 0 && <TicketHeader />}
            </div>
        );
    };

    return (
        <Lightbox className="lb-w1200 lb-overtime-delete">
            <div className="lb-overtime-delete-header">Supprimer des HNO</div>
            <div className="lb-overtime-delete-label">
                Les éléments suivants seront supprimés :
                <div className="lb-overtime-delete-description">
                    {overtimeCode.description}
                </div>
            </div>
            <div className="lb-overtime-delete-recap">
                <RecapHeader />
                {entity.hourCount > 0 || entity.ticketCount > 0
                    ? daysForOvertimeList(entity, entity.type).map((day, key) =>
                          entity.hourCount > 0 ? (
                              <HourContainer key={key} day={day} />
                          ) : (
                              <TicketLine key={key} day={day} />
                          )
                      )
                    : daysForOvertimeList(entity, entity.type).map(
                          (day, key) => (
                              <div
                                  key={key}
                                  className="overtime-delete-constraint-line"
                              >
                                  <div className="overtime-delete-date">
                                      {moment.parseZone(day.date).format("dddd D MMMM")}
                                  </div>
                              </div>
                          )
                      )}
            </div>
            <div className="lb-overtime-delete-actions">
                <div
                    onClick={() => changeOverlayContent(null)}
                    className="lb-overtime-delete-cancel"
                >
                    Annuler
                </div>
                <div
                    onClick={handleSubmit}
                    className="lb-overtime-delete-confirm"
                >
                    Supprimer
                </div>
            </div>
        </Lightbox>
    );
};

export default DeleteOvertime;
