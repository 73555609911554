import React from "react";
import { Link } from "react-router-dom";

import "./PressWidget.scss";

const PressWidget = (props) => {
    const post = props.post;

    return (
        <Link className="container-press-release" to={`/actus/${post.id}`}>
            <div className="press-release-title">Communiqué de presse</div>
            <img src={post.homepagePicture} alt={post.title} />
            <div className="press-release-content">{post.title}</div>
        </Link>
    );
};

export default PressWidget;
