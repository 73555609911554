import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Calendar from "components/TimesheetContainer/Calendar/Calendar";
import EventContainer from "components/TimesheetContainer/Calendar/EventContainer";
import { Redirect } from "react-router-dom";
import VacationCount from "components/TimesheetContainer/VacationCount/VacationCount";

import moment from "moment";
import "moment/locale/fr";

import * as TimesheetActions from "actions/Timesheet";
import CalendarNotAvailable from "./Calendar/CalendarNotAvailable";

const TimesheetContainer = () => {
    const me = useSelector((state) => state.user.me);
    const vacationCount = useSelector((state) => state.user.vacationCount);
    const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const dispatch = useDispatch();
    const {
        month = moment.parseZone().month() + 1,
        year = moment.parseZone().year(),
    } = useParams();

    let fetchable = false;

    const lowerLimit =
        moment(me.seniorityAt) > moment("01-01-2019", "DD-MM-YYYY")
            ? moment(me.seniorityAt)
            : moment("01-01-2019", "DD-MM-YYYY");

    const upperLimit = moment().add("1", "years").startOf("month");

    const targetMonth = moment(
        `01-${String(month).padStart(2, "0")}-${year}`,
        "DD-MM-YYYY"
    );

    const canDisplayCra = !me.hasRole("ROLE_NOT_CRA");

    const lowerLimitMonth = lowerLimit.startOf("month");

    if (
        (parseInt(year) &&
            parseInt(month) &&
            targetMonth >= lowerLimitMonth &&
            targetMonth <= upperLimit) ||
        (typeof year === "undefined" && typeof month === "undefined")
    ) {
        fetchable = true;
    }

    useEffect(() => {
        if (me.loaded && canDisplayCra) {
            if (fetchable) {
                // dispatch(PeriodActions.getCurrentPeriod());
                dispatch(TimesheetActions.fetchEntireMonth(month, year));
            }
        }
    }, [me, month, year]);

    return me.loaded && me.seniorityAt ? (
        fetchable ? (
            <div id="page-container">
                <div className="container">
                    {canDisplayCra ? (
                        <Fragment>
                            <EventContainer
                                firstDayOfMonth={targetMonth}
                                currentMonth={monthEntity.month}
                                currentYear={monthEntity.year}
                                days={monthEntity.days}
                                mainActivity={monthEntity.mainActivity}
                                autofillable={monthEntity.autofillable}
                                isMonthSubmitted={monthEntity.submitted}
                                isMonthEditable={monthEntity.editable}
                                isSpecialTime={monthEntity.specialTime}
                                me={me}
                            />
                            <Calendar
                                monthEntity={monthEntity}
                                days={monthEntity.days}
                                me={me}
                            />
                            {me.type !== "contractor" && (
                                <VacationCount
                                    cp={vacationCount.cp}
                                    rtt={vacationCount.rtt}
                                    remainder={vacationCount.remainder}
                                    beforeJune={vacationCount.beforeJune}
                                    sickChildren={
                                        vacationCount.sickChildrenDays
                                    }
                                    displayRemainderCounter={
                                        me.displayRemainderCounter
                                    }
                                />
                            )}
                            {/* <TutorialLink /> */}
                        </Fragment>
                    ) : (
                        <CalendarNotAvailable />
                    )}
                </div>
            </div>
        ) : (
            <Redirect to="/not-found" />
        )
    ) : (
        ""
    );
};

export default TimesheetContainer;
