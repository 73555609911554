import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import "./LoginContainer.scss";
import InputText from "components/LoginContainer/Form/InputText";
import InputPassword from "components/LoginContainer/Form/InputPassword";
import { API_ROOT } from "config/Parameters";
import { useHistory, useLocation, Link } from "react-router-dom";
import { setAuthenticated, setAppLoading, setLoading } from "actions/Common";
import { useDispatch } from "react-redux";
import * as UserActions from "actions/User";
import Disclaimer from "components/common/Disclaimer";

const LoginContainer = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { from } = location.state || { from: { pathname: "/" } };

    const [values, setValues] = useState({
        username: "",
        password: "",
        error: false,
    });

    function handleInputChange(e) {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    function handleSubmit(e) {
        if (values.username.length > 1 && values.password.length >= 5) {
            dispatch(setLoading(true));
            e.preventDefault();
            fetch(API_ROOT + "login_check", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                credentials: "include",
                mode: "cors",
                body: JSON.stringify({
                    username: values.username,
                    password: values.password,
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 503) {
                            setValues({
                                ...values,
                                error: "Maintenance en cours. Veuillez réessayer plus tard.",
                            });
                        }
                        return Promise.reject(response);
                    } else {
                        return response.json();
                    }
                })
                .then((json) => {
                    const data = jwt_decode(json.token);
                    localStorage.setItem("synchroneTokenExpiration", data.exp);
                    localStorage.removeItem("switchUser");
                    setValues({ error: false });
                    dispatch(setAuthenticated(true));
                    dispatch(setLoading(false));
                    dispatch(setAppLoading(true));
                    dispatch(UserActions.fetchCurrentUser());
                    history.replace(from);
                    dispatchEvent(new Event("load"));
                })
                .catch((response) => {
                    dispatch(setLoading(false));
                    if (response instanceof Response) {
                        response.json().then((decoded) => {
                            let errorMessage;
                            if (decoded.message) {
                                switch (decoded.message) {
                                    case "Username could not be found.":
                                    case "Invalid credentials.":
                                    case "credentials.invalid":
                                        errorMessage =
                                            "Utilisateur ou mot de passe incorrect";
                                        break;

                                    default:
                                        errorMessage = decoded.message;
                                        break;
                                }
                            } else if (
                                decoded.errors[0].code ===
                                "user.account.disabled"
                            ) {
                                errorMessage =
                                    "Utilisateur ou mot de passe incorrect";
                            }
                            setValues({ ...values, error: errorMessage });
                        });
                    } else if (response instanceof Error) {
                        // console.error("ERROR CAUGHT");
                        // console.error(response);
                        setValues({
                            ...values,
                            error: "Problème de connexion. Veuillez réessayer plus tard.",
                        });
                    }
                });
        } else {
            e.preventDefault();
            let errorMessage;
            if (values.username.length === 0) {
                errorMessage = "Veuillez renseigner votre identifiant";
            } else if (values.password.length === 0) {
                errorMessage = "Veuillez renseigner votre mot de passe";
            } else if (values.username.length === 1) {
                errorMessage = "Veuillez renseigner un identifiant valide";
            } else if (values.password.length < 5) {
                errorMessage = "Veuillez renseigner un mot de passe valide";
            }
            setValues({
                ...values,
                error: errorMessage,
            });
        }
    }

    function renderError() {
        if (values.error) {
            return <div className="login-error">{values.error}</div>;
        }
    }

    return (
        <div id="login-container">
            <div id="form-login-container">
                {renderError()}
                <img src="/logo-my-synchrone-w.svg" alt="My Synchrone" />
                <form onSubmit={(e) => handleSubmit(e)}>
                    <InputText
                        placeholder="Identifiant"
                        value={values.username}
                        name="username"
                        onChange={(e) => handleInputChange(e)}
                    />
                    <InputPassword
                        placeholder="Mot de passe"
                        value={values.password}
                        name="password"
                        onChange={(e) => handleInputChange(e)}
                    />
                    <Link className="forgot-password" to="/forgot-password">
                        Mot de passe oublié ?
                    </Link>
                    <input type="submit" value="Se connecter" />
                </form>
            </div>
            <Disclaimer login={true} />
        </div>
    );
};

export default LoginContainer;
