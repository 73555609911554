import React, { useEffect, useState, useRef } from "react";
import { b64_to_utf8 } from "utils/Utils";

const TwitterWidget = (props) => {
    const [loaded, setLoaded] = useState(false);
    const post = props.post;
    const widgetContainer = useRef(null);
    const toggleEvent = new Event("toggleTwitter");

    const setVisible = () => {
        if (!loaded) {
            setLoaded(true);
        }
    };

    useEffect(() => {
        if (window.twttr && !loaded) {
            window.addEventListener("toggleTwitter", setVisible);
            window.twttr.widgets.load();
            window.twttr.events.bind("rendered", () => {
                window.dispatchEvent(toggleEvent);
            });
        }
        return () => window.removeEventListener("toggleTwitter", setVisible);
    }, []);

    return (
        <div
            ref={widgetContainer}
            dangerouslySetInnerHTML={{ __html: b64_to_utf8(post.content) }}
            className={!loaded ? "hidden" : ""}
        ></div>
    );
};

export default TwitterWidget;
