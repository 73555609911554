import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import Button from "components/TimesheetContainer/Forms/Event/Button";

import * as TimesheetActions from "actions/Timesheet";
import EventCode from "entities/EventCode";
import OvertimeCode from "entities/OvertimeCode";
import {
    EntityRepository,
    DAY_REPOSITORY,
    HALFDAY_REPOSITORY,
} from "store/EntityRepository";
import { STEP1 } from "components/TimesheetContainer/Forms/Event/Edit";

const Step1Actions = (props) => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.user.me);
    const [canOvertime, setCanOvertime] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        selectedStartDay,
        selectedEndDay,
        startAt,
        endAt,
        nextStep,
        setAffectations,
        setSelectedEventDescription,
        setSelectedEventClass,
        setAllowedEventCodes,
        setSelectedType,
        setAllowedOvertimeCodes,
        overtimes,
        monthEntity,
    } = props;
    const [realSelectedStartDay, setRealSelectedStartDay] = useState(
        selectedStartDay ? selectedStartDay.day : selectedStartDay
    );
    const [realSelectedEndDay, setRealSelectedEndDay] = useState(
        selectedEndDay ? selectedEndDay.day : selectedEndDay
    );
    const dayRepo = EntityRepository().getRepository(DAY_REPOSITORY);
    const halfDayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);
    const currentPeriod = useSelector((state) => state.user.me.currentPeriod);
    const today = currentPeriod.year + "-" + currentPeriod.month;

    useEffect(() => {
        setLoading(true);
        Promise.resolve(
            dispatch(TimesheetActions.listOvertimeCodes(false, startAt, endAt))
        ).then((response) => {
            setLoading(false);
            const overtimeCodes = response.response.entities.overtimeCodes;
            if (overtimeCodes) {
                setCanOvertime(true);
            }
        });
    }, []);

    // useEffect(() => {
    //     if (realSelectedStartDay.pm) {
    //         console.log(
    //             halfDayRepo.findWithoutHook(realSelectedStartDay.pm)
    //                 .workingState !== "worked" || !realSelectedStartDay.pm
    //         );
    //     }

    //     if (realSelectedStartDay.pm) {
    //         console.log(
    //             halfDayRepo.findWithoutHook(realSelectedStartDay.pm)
    //                 .workingState
    //         );
    //     }
    // }, [realSelectedStartDay]);

    useEffect(() => {
        if (!selectedStartDay) {
            setRealSelectedStartDay(dayRepo.findForDateWithoutHook(startAt));
        }
    }, [startAt]);

    useEffect(() => {
        if (!selectedEndDay) {
            setRealSelectedEndDay(dayRepo.findForDateWithoutHook(endAt));
        }
    }, [endAt]);

    const isSameMonth = () => {
        if (
            moment.parseZone(startAt).month() !==
            moment.parseZone(endAt).month()
        ) {
            return false;
        }
        return true;
    };

    const postEventType = (description, classname) => {
        setSelectedEventDescription(description);
        setSelectedEventClass(classname);

        Promise.resolve(
            dispatch(TimesheetActions.fetchEventCodes(false, startAt, endAt))
        ).then((response) => {
            const eventCodes = response.response.entities.eventCodes;
            let tempEventCodes = [];
            if (eventCodes) {
                tempEventCodes = Object.values(eventCodes).filter(
                    (eventCode) => eventCode.type === classname
                );
            }
            tempEventCodes.sort((a, b) => {
                return b.priority - a.priority;
            });
            let eventCodeEntities = [];
            tempEventCodes.forEach((element) => {
                eventCodeEntities.push(new EventCode(element));
            });
            setAllowedEventCodes(eventCodeEntities);
            nextStep(classname);
            setSelectedType("event");
        });
    };

    const postOvertimeType = (description, classname) => {
        setSelectedEventDescription(description);
        setSelectedEventClass(classname);
        Promise.resolve(
            dispatch(TimesheetActions.listOvertimeCodes(false, startAt, endAt))
        ).then((response) => {
            const overtimeCodes = response.response.entities.overtimeCodes;
            let tempOvertimeCodes = [];
            if (overtimeCodes) {
                tempOvertimeCodes = Object.values(overtimeCodes);
            }
            let overtimeCodeEntities = [];
            // let rangeHasConstraint = false;
            // overtimes.map((element) => {
            //     if (
            //         moment.parseZone(element.startAt) <= moment.parseZone(startAt) &&
            //         moment.parseZone(element.endAt) >= moment.parseZone(endAt)
            //     ) {
            //         let matchingOvertimeCode = tempOvertimeCodes.find(
            //             (code) => code.id === element.code
            //         );
            //         if (matchingOvertimeCode) {
            //             if (matchingOvertimeCode.category === "availability") {
            //                 rangeHasConstraint = true;
            //             }
            //         }
            //     }
            // });
            tempOvertimeCodes.forEach((element) => {
                if (element.category === "intervention") {
                    if (
                        // (rangeHasConstraint &&
                        element.sub_category === "constraint" ||
                        element.sub_category !== "constraint"
                    ) {
                        overtimeCodeEntities.push(new OvertimeCode(element));
                    }
                } else {
                    if (
                        element.category === "availability"
                        //  && !rangeHasConstraint
                    ) {
                        overtimeCodeEntities.push(new OvertimeCode(element));
                    }
                }
            });
            setAllowedOvertimeCodes(overtimeCodeEntities);
            setAffectations(getAffectations(overtimeCodeEntities));
            nextStep(classname, true);
            setSelectedType("overtime");
        });
    };

    const getAffectations = (codes) => {
        let result = [];
        let array = Object.values(codes);
        array.forEach((element) => {
            if (result.indexOf(element.affectation) === -1) {
                result.push(element.affectation);
            }
        });
        return result;
    };

    const formattedMonth = (month, year) => {
        let monthString = "";
        if (month <= 10) {
            monthString = `0${month}`;
        } else {
            monthString = month;
        }

        return monthString + "-" + year;
    };

    // const today = moment.parseZone().format("MM-YYYY");

    const Buttons = () => {
        return (
            <Fragment>
                {isSameMonth() &&
                ((realSelectedStartDay.worked && realSelectedEndDay.worked) ||
                    monthEntity.specialTime) &&
                ((realSelectedStartDay.id !== realSelectedEndDay.id &&
                    (halfDayRepo.findWithoutHook(realSelectedStartDay.pm)
                        ?.workingState !== "worked" ||
                        !realSelectedStartDay.pm) &&
                    (halfDayRepo.findWithoutHook(realSelectedEndDay.am)
                        ?.workingState !== "worked" ||
                        !realSelectedEndDay.am)) ||
                    (realSelectedStartDay.id === realSelectedEndDay.id &&
                        (!realSelectedStartDay.am ||
                            !realSelectedStartDay.pm ||
                            halfDayRepo.findWithoutHook(realSelectedStartDay.am)
                                ?.workingState !== "worked" ||
                            halfDayRepo.findWithoutHook(realSelectedStartDay.pm)
                                ?.workingState !== "worked"))) ? (
                    <Fragment>
                        {monthEntity &&
                            formattedMonth(
                                monthEntity.month,
                                monthEntity.year
                            ) <= today && (
                                <Button
                                    text={"Mes jours travaillés"}
                                    type="activity"
                                    onClick={() =>
                                        postEventType(
                                            "Mes jours travaillés",
                                            "activity"
                                        )
                                    }
                                />
                            )}

                        {me.type !== "contractor" && (
                            <Button
                                text={"Mes absences"}
                                type="absence"
                                onClick={() =>
                                    postEventType("absence", "absence")
                                }
                            />
                        )}
                    </Fragment>
                ) : (
                    ""
                )}

                {isSameMonth() &&
                monthEntity &&
                formattedMonth(monthEntity.month, monthEntity.year) <= today &&
                canOvertime ? (
                    <Button
                        text={"Mes HNO"}
                        type="hours-not-worked"
                        onClick={() =>
                            postOvertimeType("Mes HNO", "hours-not-worked")
                        }
                    />
                ) : (
                    ""
                )}
            </Fragment>
        );
    };

    return (
        <div
            className={
                (props.step !== STEP1 ? "hidden " : "") +
                "step1-actions event-type-buttons"
            }
        >
            {!loading && <Buttons />}
        </div>
    );
};

export default Step1Actions;
