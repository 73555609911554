import React, { useEffect } from "react";

import moment from "moment";
import "moment/locale/fr";
import "./FacebookWidget.scss";

const FacebookWidget = (props) => {
    const post = props.post;

    useEffect(() => {
        if (window.FB) {
            window.FB.XFBML.parse();
        }
    }, []);

    return (
        <div className="container-facebook">
            <div className="img-facebook">
                {post.homepageImageUrl && (
                    <a
                        href={post.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={post.homepagePicture} alt={post.title} />
                    </a>
                )}
            </div>
            <div className="content-center">
                <div className="link-facebook">
                    <img
                        className="synchrone-img"
                        src="img/synchrone.png"
                        alt="synchrone_image"
                    />
                    <div className="link-facebook-synchrone">
                        <span className="span-synchrone">
                            <a
                                href="https://www.facebook.com/SynchroneFr/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Synchrone
                            </a>
                        </span>
                        <span className="span-synchrone-fr">
                            le {moment.parseZone(post.publishedAt).format("DD/MM/YYYY")}
                        </span>
                    </div>
                    <div className="facebook-logo">
                        <a
                            href={post.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i
                                className="fab fa-facebook-square"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                </div>
                <div
                    className="content-facebook"
                    dangerouslySetInnerHTML={{
                        __html: post.homepageContent,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default FacebookWidget;
