import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./Overlay.scss";
import {
    subscribeToOverlayChange,
    unsubscribeToOverlayChange,
} from "events/OverlayEvents";

const Overlay = (props) => {
    const [content, setContent] = useState();

    const updateContent = (e) => {
        setContent(e.detail.component);
    };

    useEffect(() => {
        subscribeToOverlayChange(updateContent);
        return function () {
            unsubscribeToOverlayChange(updateContent);
        };
    }, []);

    return (
        <div id="overlay" className={content ? "open" : ""}>
            {content}
        </div>
    );
};

export default Overlay;
