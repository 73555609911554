import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "components/common/Lightbox";
import * as ValidationActions from "actions/Validation";

import moment from "moment";
import "moment/locale/fr";

import "./AbsenceHistoryActions.scss";
import { changeOverlayContent } from "events/OverlayEvents";
import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";

const AbsenceHistoryActions = (props) => {
    const { event, user, manager } = props;
    const dispatch = useDispatch();
    const eventActions = useSelector((state) => state.validation.eventActions);

    useEffect(() => {
        dispatch(ValidationActions.fetchEventAction(event.id));
    }, []);

    const close = () => {
        changeOverlayContent(null);
    };

    const AbsenceHistoryRow = (props) => {
        const { eventAction } = props;

        const actionDescription = () => {
            let description = "";
            switch (eventAction.action) {
                case "create":
                    description = "Absence envoyée";
                    break;
                case "backward":
                    description = "Absence à modifier";
                    break;
                case "validated1":
                    description = "Absence validée Niv 1";
                    break;
                case "validated2":
                    description = "Absence validée Niv 2";
                    break;
                case "refused":
                    description = "Absence refusée";
                    break;
                case "edit":
                    description = "Absence modifiée";
                    break;

                default:
                    description = eventAction.action;
                    break;
            }

            return description;
        };

        return (
            <div className="absence-history-actions-row">
                <div className="actions-row-description">
                    {actionDescription()}
                </div>
                <div className="actions-row-date">
                    {moment.parseZone(eventAction.createdAt).format("DD/MM/YYYY")}
                </div>
                <div className="actions-row-old-data">
                    {eventAction.oldData}
                </div>
                <div className="actions-row-new-data">
                    {eventAction.newData ? eventAction.newData : "—"}
                </div>
                <div className="actions-row-author">
                    {eventAction.author ? (
                        eventAction.author.firstname +
                        " " +
                        eventAction.author.lastname
                    ) : (
                        <span className="italic-label">Système</span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Lightbox className="lb-centered absence-history-actions-lb">
            <div className="absence-history-actions-header">
                <div className="absence-history-actions-title">
                    Historique des actions sur l'absence
                </div>
                <div className="absence-history-actions-identity-block">
                    <div className="absence-history-actions-identity">
                        {user.identity}
                    </div>
                    <PersonTypeBadge personType={user.type} />
                    <div className="absence-history-actions-matricule">
                        {user.matricule}
                    </div>
                    <div className="absence-history-actions-manager">
                        Responsable : {manager && manager.identity}
                    </div>
                </div>
                <div className="close-lb" onClick={close}>
                    <i className="fal fa-times"></i>
                </div>
            </div>
            <div className="absence-history-actions-table-header">
                <div className="table-header-description">Action</div>
                <div className="table-header-date">Date</div>
                <div className="table-header-old-data">Info initiale</div>
                <div className="table-header-new-data">
                    Info après modification
                </div>
                <div className="table-header-author">Auteur</div>
            </div>
            <div className="absence-history-actions-content">
                {eventActions.map((element, key) => {
                    return (
                        <AbsenceHistoryRow key={key} eventAction={element} />
                    );
                })}
            </div>
        </Lightbox>
    );
};

export default AbsenceHistoryActions;
