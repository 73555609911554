class Overtime {
    id;
    code;
    type;
    state;
    startAt;
    endAt;
    locked;
    days;
    constraintCount;
    hourCount;
    ticketCount;
    createdAt;
    updatedAt;

    constructor(json) {
        this.id = json.id;
        this.code = json.code;
        this.type = json.type;
        this.state = json.state;
        this.startAt = json.start_at;
        this.endAt = json.end_at;
        this.locked = json.locked;
        this.days = json.days;
        this.constraintCount = json.constraint_count;
        this.hourCount = json.hour_count;
        this.comment = json.comment;
        this.ticketCount = json.ticket_count;
        this.createdAt = json.created_at;
        this.updatedAt = json.updated_at;
    }
}

export default Overtime;
