import React from "react";
import { Link } from "react-router-dom";

import "./RexWidget.scss";

const RexWidget = (props) => {
    const post = props.post;

    return (
        <Link className="rex" to={`/actus/${post.id}`}>
            <div className="rex-title">{post.title}</div>
            <img src={post.picture} alt={post.title} />
        </Link>
    );
};

export default RexWidget;
