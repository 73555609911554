import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import "moment/locale/fr";
import FilterContainer from "components/Backend/FilterContainer";

import * as UserActions from "actions/User";
import * as ValidationActions from "actions/Validation";
import { setLoading } from "actions/Common";

import "./AbsenceContainer.scss";
import { useSelector, useDispatch } from "react-redux";
import PersonRow from "components/Backend/AbsenceContainer/PersonRow";
import * as Params from "config/Parameters";

const ALL = "all";
// const SUBMITTED = "submitted";
// const CANCELED = "canceled";
// const REFUSED = "refused";
// const DELETED = "deleted";
// const VALIDATED_LEVEL_1 = "validated1";
// const VALIDATED_LEVEL_2 = "validated2";

const AbsenceContainer = () => {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);
    const [needUserListUpdate, setNeedUserListUpdate] = useState(false);
    const me = useSelector((state) => state.user.me);
    const [minDate, setMinDate] = useState(
        me.currentPeriod
            ? moment(
                  me.currentPeriod.year +
                      "-" +
                      me.currentPeriod.month.toString().padStart(2, "0") +
                      "01",
                  "YYYY-MM-DD"
              )
                  .subtract("6", "M")
                  .format("YYYY-MM")
            : ""
    );
    const [maxDate, setMaxDate] = useState(
        me.currentPeriod
            ? moment(minDate + "-01", "YYYY-MM-DD")
                  .add("11", "M")
                  .format("YYYY-MM")
            : ""
    );
    const [periods, setPeriods] = useState([]);
    const today = moment.parseZone().subtract(1, "months");
    const totalUsersCount = useSelector(
        (state) => state.validation.totalUsersCount
    );
    const maxPage = Math.ceil(totalUsersCount / 10);

    const initFilters = () => {
        let filters = {
            period: "",
            types: null,
            person: null,
            company: null,
            manager: null,
            states: [],
            minDate: minDate,
            maxDate: maxDate,
        };

        if (
            me.hasRole("ROLE_FEE_MANAGER") ||
            me.hasRole("ROLE_PAY_MANAGER") ||
            me.hasRole("ROLE_MANAGER") ||
            me.hasRole("ROLE_BILLING_SERVICE")
        ) {
            filters.types = [ALL];
        }

        if (me.hasRole("ROLE_ABSENCE_ACCEPT_SECOND_LEVEL")) {
            filters.states.push("validated1");
        }

        if (me.hasRole("ROLE_ABSENCE_ACCEPT_FIRST_LEVEL")) {
            filters.manager = me.matricule;
            filters.states.push("submitted");
        }

        return filters;
    };
    const [filters, setFilters] = useState(initFilters());
    const resetFilters = () => {
        setFilters(initFilters());
    };

    const loadList = () => {
        dispatch(setLoading(true));

        if (filters.period) {
            filters.minDate = null;
            filters.maxDate = null;
        } else {
            filters.minDate = minDate;
            filters.maxDate = maxDate;
        }
        dispatch(
            ValidationActions.fetchAbsenceList(
                filters,
                Params.MAX_RESULTS_FOR_VALIDATION,
                page
            )
        );
    };

    useEffect(() => {
        let tempPeriods = [];
        for (let index = 0; index < 12; index++) {
            tempPeriods.push({
                value: today.format("YYYY-MM"),
                label: today.format("YYYY-MM"),
            });
            today.add(1, "months");
        }
        setPeriods(tempPeriods);

        if (!localStorage.getItem("userForAbsenceList")) {
            let periodRange = [];
            periodRange["startDate"] = filters["minDate"];
            periodRange["endDate"] = filters["maxDate"];

            dispatch(UserActions.fetchAll(periodRange)).then((json) => {
                localStorage.setItem(
                    "userForAbsenceList",
                    JSON.stringify(json.response.entities.user)
                );
                setNeedUserListUpdate(true);
            });
        }
        dispatch(UserActions.fetchManagerList());
    }, []);

    useEffect(() => {
        if (me.loaded) {
            loadList();
        }
    }, [filters, page]);

    const usersToDisplay = useSelector((state) => state.validation.users);
    const managers = useSelector((state) => state.user.listManagers);

    return (
        <div className="absence-container">
            <FilterContainer
                filters={filters}
                setFilters={setFilters}
                // users={usersAvailable}
                managers={managers}
                resetFilters={resetFilters}
                type="absence"
                periods={periods}
                currentPeriodIndex={currentPeriodIndex}
                setCurrentPeriodIndex={setCurrentPeriodIndex}
                needUserListUpdate={needUserListUpdate}
                setNeedUserListUpdate={setNeedUserListUpdate}
                setPage={setPage}
            />

            <div className="absence-list">
                <div className="absence-list-title">Valider les absences</div>
                {totalUsersCount > 0 ? (
                    <Fragment>
                        <div className="absence-list-header">
                            <span className="header-identity">
                                Collaborateur
                            </span>
                            <span className="header-absence-type">
                                Type d'absence
                            </span>
                            <span className="header-days-amount">Jours</span>
                            <span className="header-actions">
                                <div>Actions</div>
                                <div>
                                    <span className="validation-level">
                                        Niv1
                                    </span>
                                    <span className="validation-level">
                                        Niv2
                                    </span>
                                </div>
                            </span>
                            <span className="header-vacation-count">
                                Solde en temps réel
                            </span>
                        </div>
                        {usersToDisplay
                            .sort((a, b) => {
                                return a.lastname.localeCompare(b.lastname);
                            })
                            .map((element, key) => {
                                // let relevantEvents = events.filter((item) => {
                                //     return item.user === element.id;
                                // });
                                return (
                                    <PersonRow
                                        user={element}
                                        key={key}
                                        events={element.absences}
                                        loadList={loadList}
                                        period={
                                            periods[currentPeriodIndex]
                                                ? moment.parseZone(
                                                      periods[
                                                          currentPeriodIndex
                                                      ].value
                                                  )
                                                : []
                                        }
                                    />
                                );
                            })}
                    </Fragment>
                ) : (
                    <div className="no-absence">
                        Aucune absence ne correspond à vos filtres
                    </div>
                )}
            </div>

            {totalUsersCount > 0 && (
                <div className="page-controls">
                    {page > 1 && (
                        <span
                            onClick={() => setPage(page - 1)}
                            className="previous-page fal fa-chevron-left"
                        ></span>
                    )}
                    <div className="page-number">
                        {page} / {maxPage}
                    </div>
                    {page < maxPage && (
                        <span
                            onClick={() => setPage(page + 1)}
                            className="next-page fal fa-chevron-right"
                        ></span>
                    )}
                </div>
            )}
        </div>
    );
};

export default AbsenceContainer;
