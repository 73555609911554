import React from "react";
import { Link } from "react-router-dom";

import "./ZoomWidget.scss";

const ZoomWidget = (props) => {
    const post = props.post;

    return (
        <Link className="marketing-post" to={`/actus/${post.id}`}>
            <img src={post.picture} alt={post.title} />
        </Link>
    );
};

export default ZoomWidget;
