import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Lightbox from "components/common/Lightbox";
import CheckBox from "components/TimesheetContainer/Calendar/CheckBox";

import { monthInLetter, isEmpty } from "utils/Utils";
import { changeOverlayContent } from "events/OverlayEvents";
import * as TimesheetActions from "actions/Timesheet";
import { setLoading } from "actions/Common";

import "./ConfirmSendMonth.scss";
import SpecificCRAContainer from "./SpecificCRAContainer";

const ConfirmSendMonth = (props) => {
    const dispatch = useDispatch();
    const { month, personType, year, hasEvents } = props;
    const [certified, setCertified] = useState(false);
    const [special, setSpecial] = useState(false);
    const [comment, setComment] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [files, setFiles] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [noPrestationChecked, setNoPrestationChecked] = useState(false);
    const [touched, setTouched] = useState(false);

    const handleSubmit = () => {
        if (special) {
            if (isEmpty(files)) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Veuillez fournir au moins 1 fichier de CRA spécifique"
                    )
                );
                return;
            }
            if (!certified) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Vous devez certifier la conformité du CRA spécifique"
                    )
                );
                return;
            }
        }

        if (personType === "contractor" && !hasEvents) {
            if (!noPrestationChecked) {
                dispatch(
                    TimesheetActions.notifyError(
                        "Veuillez confirmer que vous n'avez effectué aucune prestation ce mois-ci"
                    )
                );
                return;
            }
        }

        if (!submitting) {
            setSubmitting(true);
            let json = {
                certified: certified,
                special: special,
                comment: comment,
            };
            if (noPrestationChecked) {
                json.no_prestation = true;
            }
            let filesArray = [];
            files.map((element) => {
                let file = {
                    file: { file: element.path + "||" + element.base64 },
                };
                filesArray.push(file);
            });
            json.files = filesArray;
            dispatch(setLoading(true));
            return dispatch(
                TimesheetActions.postMonth(month, year, JSON.stringify(json))
            ).then(() => {
                changeOverlayContent(null);
                setSubmitting(false);
                return Promise.resolve(
                    dispatch(TimesheetActions.clearDaysSelection())
                ).then(() => {
                    dispatch(setLoading(false));
                    Promise.all([
                        dispatch(
                            TimesheetActions.fetchEntireMonth(month, year)
                        ),
                        dispatch(
                            TimesheetActions.notifySuccess(
                                "Votre déclaration a été envoyée"
                            )
                        ),
                    ]);
                });
            });
        }
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const getMonthPreposition = () => {
        let firstLetterOfMonth = monthInLetter(month).charAt(0);
        if (firstLetterOfMonth === "A" || firstLetterOfMonth === "O") {
            return "d'";
        }

        return "de";
    };

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                <div className="lb-confirm-send-header">ENVOYER MON CRA</div>
                <div className="lb-confirm-send-text">
                    Je confirme l'exactitude des informations saisies pour le
                    mois {getMonthPreposition()}{" "}
                    <span className="month-bold">{monthInLetter(month)}</span>
                </div>
                {personType === "contractor" && !hasEvents && (
                    <CheckBox
                        label="Aucune prestation ce mois-ci"
                        name="no-prestation"
                        value={noPrestationChecked}
                        onClick={() =>
                            setNoPrestationChecked(!noPrestationChecked)
                        }
                    />
                )}
                <div className="lb-confirm-send-comment-label">
                    <i className="far fa-comment-alt"></i> Commentaire
                </div>
                <textarea
                    name="comment"
                    value={comment}
                    onChange={(e) => handleCommentChange(e)}
                    className="lb-confirm-send-comment"
                ></textarea>
                {(personType === "consultant" ||
                    personType === "contractor") && (
                    <SpecificCRAContainer
                        files={files}
                        setFiles={setFiles}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        special={special}
                        setSpecial={setSpecial}
                        certified={certified}
                        setCertified={setCertified}
                        afterSubmit={false}
                        touched={touched}
                        setTouched={setTouched}
                    />
                )}
                <div className="lb-confirm-send-actions">
                    <div
                        onClick={() => changeOverlayContent(null)}
                        className="lb-confirm-send-cancel"
                    >
                        Annuler
                    </div>
                    <div
                        onClick={handleSubmit}
                        className="lb-confirm-send-confirm"
                    >
                        Envoyer mon CRA
                    </div>
                </div>
            </div>
        </Lightbox>
    );
};

export default ConfirmSendMonth;
