import React, { useEffect, useRef } from "react";
import { b64_to_utf8 } from "utils/Utils";

const InstagramWidget = (props) => {
    const post = props.post;
    const widgetContainer = useRef(null);

    const fakeInterval = () => {
        setTimeout(() => {
            if (window.instgrm) {
                window.instgrm.Embeds.process(widgetContainer.current);
            } else {
                fakeInterval();
            }
        }, 100);
    };

    useEffect(() => {
        fakeInterval();
    }, []);

    return (
        <div
            ref={widgetContainer}
            dangerouslySetInnerHTML={{
                __html: b64_to_utf8(post.content),
            }}
        ></div>
    );
};

export default InstagramWidget;
