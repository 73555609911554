import React, { useEffect, useState } from "react";
import { changeOverlayContent } from "events/OverlayEvents";
import { API_ROOT } from "config/Parameters";
import ReactPlayer from "react-player";
import "./CyberPopUp.scss";

const CyberPopUp = (props) => {
    const [clickableVideoClose, setClickableVideoClose] = useState(false);
    const { setPopupVisible } = props;

    const close = () => {
        localStorage.setItem("cybersecurityPopupClosed", true);
        changeOverlayContent(null);
        setPopupVisible(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setClickableVideoClose(true);
        }, 5000);
    }, []);

    return (
        <div id="video-cyber-popup">
            <div
                id="video-cyber-popup-close"
                className={clickableVideoClose ? "" : "hidden"}
                onClick={close}
            ></div>
            <ReactPlayer
                onContextMenu={(e) => {
                    e.preventDefault();
                    return false;
                }}
                url={`${API_ROOT}video`}
                controls={true}
                config={{
                    file: {
                        attributes: {
                            controlsList: "nodownload",
                        },
                    },
                }}
                playing={true}
                muted={true}
                width="632px"
                height="359px"
                style={{
                    margin: "0 auto",
                    border: "4px solid #007288",
                    backgroundColor: "#007288",
                }}
            />
            <div id="video-popup-subtitle">
                #1 L'arnaque au faux support technique
            </div>
        </div>
    );
};

export default CyberPopUp;
