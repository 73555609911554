import React from "react";
import "./NewsContainer.scss";

import NewsFeed from "./NewsFeed";
import { useParams } from "react-router";
import PostView from "./PostView";
import useScript from "hooks/useScript";

const Newscontainer = (props) => {
    const { postId } = useParams();

    useScript("https://www.instagram.com/embed.js");

    const fbScript = document.createElement("script");
    fbScript.src =
        "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v3.2";
    fbScript.async = true;

    document.body.appendChild(fbScript);

    return (
        <div id="news-container">
            {postId ? (
                <PostView postId={postId} />
            ) : (
                <NewsFeed recommandation={false} />
            )}
        </div>
    );
};

export default Newscontainer;
