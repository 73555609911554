import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import "./Step2Actions.scss";

import Button from "components/TimesheetContainer/Forms/Event/Button";
import ButtonList from "components/TimesheetContainer/Forms/Event/StepActions/ButtonList";
import { STEP2 } from "components/TimesheetContainer/Forms/Event/Edit";

const Step2Actions = (props) => {
    const me = useSelector((state) => state.user.me);

    const {
        allowedOvertimeCodes,
        nextStep,
        step,
        constraintCategory,
        remoteWorking,
        setRemoteWorking,
        allowedEventCodes,
        setSelectedOvertimeCode,
        setSelectedEventCode,
        selectedEventClass,
        selectedMission,
    } = props;

    const getPriorityEventCodes = () => {
        const codes = [];
        if (allowedEventCodes) {
            allowedEventCodes.map((item, key) => {
                if (key < 2) {
                    codes.push(item);
                }
            });
        }
        return codes;
    };
    const priorityEventCodes = getPriorityEventCodes();

    const getOtherEventCodes = () => {
        const codes = [];
        if (allowedEventCodes) {
            allowedEventCodes.map((item, key) => {
                if (key >= 2) {
                    codes.push(item);
                }
            });
        }
        return codes;
    };
    const otherEventCodes = getOtherEventCodes();

    const postOvertimeCode = (item) => {
        setSelectedOvertimeCode(item);
        nextStep();
    };

    const postEventCode = (item) => {
        setSelectedEventCode(item);
        nextStep();
    };

    const OvertimeCodes = (props) => {
        const codes = allowedOvertimeCodes.filter((element, index) => {
            return (
                element.category === constraintCategory &&
                selectedMission &&
                element.affectation === selectedMission.code &&
                element.active
            );
        });

        codes.forEach((element) => {
            element.type = "hours-not-worked";
            element.shortCode = "-";
        });

        const otherCodes = codes.filter((element, index) => {
            return index >= 2;
        });

        return (
            <Fragment>
                {codes
                    ? codes.map((item, key) => {
                          if (key < 2) {
                              return (
                                  <Button
                                      key={key}
                                      text={item.description}
                                      type={item.type}
                                      shortname={item.shortCode}
                                      onClick={() => postOvertimeCode(item)}
                                      additionnalStyle={{
                                          lineHeight: "45px",
                                      }}
                                  />
                              );
                          }
                      })
                    : ""}
                {otherCodes.length > 0 ? (
                    <ButtonList
                        eventCodes={otherCodes}
                        postEventCode={postOvertimeCode}
                    />
                ) : (
                    //   }
                    ""
                )}
            </Fragment>
        );
    };

    const EventCodes = (props) => {
        return (
            <Fragment>
                {priorityEventCodes
                    ? priorityEventCodes.map((item, key) => (
                          <Button
                              key={key}
                              text={item.description}
                              type={item.type}
                              shortname={item.shortCode}
                              onClick={() => postEventCode(item)}
                          />
                      ))
                    : ""}
                {otherEventCodes.length > 0 ? (
                    <ButtonList
                        eventCodes={otherEventCodes}
                        postEventCode={postEventCode}
                    />
                ) : (
                    //   }
                    ""
                )}
            </Fragment>
        );
    };

    const RemoteWorkingCheckBox = (props) => {
        return (
            <label
                htmlFor="remote-working"
                className={
                    (props.step !== STEP2 ? "hidden " : "") +
                    "remote-working-container"
                }
                onClick={() => setRemoteWorking(!remoteWorking)}
            >
                <i className="fas remote-working-icon fa-home-lg-alt"></i>
                Activité en télétravail
                <input
                    className="checkbox"
                    type="checkbox"
                    name="remote-working"
                    defaultChecked={remoteWorking}
                />
                <span className="checkmark"></span>
            </label>
        );
    };

    return (
        <Fragment>
            {selectedEventClass === "activity" && me.type !== "contractor" && (
                <RemoteWorkingCheckBox step={step} />
            )}
            <div
                className={
                    (step !== STEP2 ? "hidden " : "") +
                    "step2-actions event-code-buttons"
                }
            >
                {selectedEventClass === "hours-not-worked" ? (
                    <OvertimeCodes />
                ) : (
                    <EventCodes />
                )}
            </div>
        </Fragment>
    );
};

export default Step2Actions;
