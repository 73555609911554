import React from "react";
import { b64_to_utf8 } from "utils/Utils";
import { useEffect } from "react";

const LinkedinWidget = (props) => {
    const post = props.post;

    return (
        <div
            dangerouslySetInnerHTML={{ __html: b64_to_utf8(post.content) }}
        ></div>
    );
};

export default LinkedinWidget;
