import React from "react";
import { Link } from "react-router-dom";

import "./ZoomWidget.scss";

const ZoomWidget = (props) => {
    const post = props.post;

    return (
        <Link className="container-marketing-article" to={`/actus/${post.id}`}>
            <div className="event-article-header">
                <i className="fa fa-search"></i> Zoom
            </div>
            <div className="event-article-title">{post.title}</div>
            {post.homepageImageUrl ? (
                <img src={post.homepagePicture} alt={post.title} />
            ) : (
                <img src={post.picture} alt={post.title} />
            )}
        </Link>
    );
};

export default ZoomWidget;
