import React from "react";
import { Link } from "react-router-dom";

import "./PressWidget.scss";

const PressWidget = (props) => {
    const post = props.post;

    return (
        <Link className="press-release" to={`/actus/${post.id}`}>
            <div className="press-release-title">
                Communiqué
                <br /> de presse
            </div>
            <div className="press-release-content">{post.title}</div>
        </Link>
    );
};

export default PressWidget;
