import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import "moment/locale/fr";
import Bricks from "bricks.js";

import * as NewsActions from "actions/News";
import { setLoading as reduxSetLoading } from "actions/Common";
import { isEmpty } from "utils/Utils";
import PostWidget from "./PostWidget";

import * as Params from "config/Parameters";

const NewsFeed = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const news = useSelector((state) => {
        let news = state.news.entities.posts;
        let array = Object.values(news);
        array.sort((a, b) => {
            return (
                moment.parseZone(b.publishedAt) -
                moment.parseZone(a.publishedAt)
            );
        });
        return array;
    });
    const sizes = [
        { columns: 3, gutter: 10 },
        // { mq: "768px", columns: 2, gutter: 3 },
        // { mq: "1024px", columns: 3, gutter: 3 },
    ];
    let bricks = Bricks({
        container: ".bricks",
        position: true,
        packed: "packed",
        sizes: sizes,
    });
    const [displayedPosts, setDisplayedPosts] = useState([]);
    const [queuedPosts, setQueuedPosts] = useState([]);

    const rearrangeLayout = (interval, duration, delay = 0) => {
        setTimeout(() => {
            let timeout = setInterval(() => {
                bricks.resize();
                bricks.pack();
                bricks.update();
                setVisible(true);
            }, interval);
            setTimeout(() => {
                clearInterval(timeout);
            }, duration);
        }, delay);
    };

    const loadNewPosts = () => {
        let newDisplayed = [...displayedPosts];
        let newQueue = [...queuedPosts];
        let toDisplay = newQueue.splice(0, Params.NEWS_AMOUNT_TO_FETCH);
        toDisplay.forEach((element) => {
            newDisplayed.push(element);
        });
        setDisplayedPosts(newDisplayed);
        setQueuedPosts(newQueue);
        setLoading(false);
        rearrangeLayout(50, 2000);
        rearrangeLayout(500, 20000, 2000);
    };

    useEffect(() => {
        if (news.length > 0 && displayedPosts.length === 0) {
            let newQueue = [...queuedPosts];
            Object.values(news).forEach((element) => {
                newQueue.push(element);
            });
            let newDisplayed = [...displayedPosts];
            let toDisplay = newQueue.splice(0, Params.NEWS_AMOUNT_TO_FETCH);
            toDisplay.forEach((element) => {
                newDisplayed.push(element);
            });
            setDisplayedPosts(newDisplayed);
            setQueuedPosts(newQueue);

            rearrangeLayout(50, 2000);
            rearrangeLayout(500, 20000, 2000);
        }
    }, [news]);

    useEffect(() => {
        bricks = Bricks({
            container: ".bricks",
            position: true,
            packed: "packed",
            sizes: sizes,
        });
        if (isEmpty(news)) {
            dispatch(reduxSetLoading(true));
            dispatch(NewsActions.fetchPosts()).then(() => {
                dispatch(reduxSetLoading(false));
            });
        } else {
            rearrangeLayout(50, 2000);
            rearrangeLayout(500, 20000, 2000);
        }

        if (!props.recommandation) {
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    useEffect(() => {
        if (!loading) return;
        loadNewPosts();
    }, [loading]);

    const handleScroll = () => {
        if (!loading) {
            if (
                window.innerHeight + document.documentElement.scrollTop ===
                document.documentElement.scrollHeight
            ) {
                setLoading(true);
            }
        }
    };

    return (
        <Fragment>
            {props.recommandation ? (
                <div className="recommandations-title">
                    Ces articles peuvent vous intéresser
                </div>
            ) : (
                <div className="news-title">Mes actualités</div>
            )}
            <div className={"bricks" + (visible ? "" : " hidden")}>
                {displayedPosts.map((element, key) => {
                    return <PostWidget key={key} post={element} />;
                })}
            </div>
        </Fragment>
    );
};

export default NewsFeed;
