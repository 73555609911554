import React, { useEffect, useState, Fragment } from "react";
import "./HomepageContainer.scss";
import { getContactDefaultRoute } from "config/Routes";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "moment/locale/fr";
import moment from "moment";
import Disclaimer from "components/common/Disclaimer";
import { changeOverlayContent } from "events/OverlayEvents";

import { EntityRepository, HIGHLIGHT_REPOSITORY } from "store/EntityRepository";

import * as NewsActions from "actions/News";
import * as UserActions from "actions/User";
import { setAppLoading } from "actions/Common";
import HomepagePost from "components/HomepageContainer/HomepagePost";
import Euro2020PopUp from "components/HomepageContainer/Euro2020PopUp";
import UnitedHeroesPopUp from "components/HomepageContainer/UnitedHeroesPopUp";
import CyberPopUp from "components/HomepageContainer/CyberPopUp/CyberPopUp";

const HomeApp = (props) => {
    const attributes = {};
    if (props.external) {
        attributes.target = "blank";
    }

    const styles = {};
    if (props.color) {
        styles.color = props.color;
    }
    if (props.bgColor) {
        styles.backgroundColor = props.bgColor;
    }
    const className = props.className
        ? props.className + " home-app"
        : "home-app";

    return (
        <div className={className} style={styles}>
            {props.pastille && (
                <img className="pastille" src="/img/pastille.svg" />
            )}
            <div className="home-app-content">{props.children}</div>
            {props.external ? (
                Array.isArray(props.button) ? (
                    props.button.map((button, index) => {
                        return (
                            <a
                                href={props.url[index]}
                                className="home-app-btn"
                                {...attributes}
                            >
                                {button}
                            </a>
                        );
                    })
                ) : (
                    <a
                        href={props.url}
                        className="home-app-btn"
                        {...attributes}
                    >
                        {props.button}
                    </a>
                )
            ) : Array.isArray(props.button) ? (
                props.button.map((button, index) => {
                    return (
                        <Link
                            to={props.url[index]}
                            key={index}
                            className="home-app-btn"
                            {...attributes}
                        >
                            {button}
                        </Link>
                    );
                })
            ) : (
                <Link to={props.url} className="home-app-btn" {...attributes}>
                    {props.button}
                </Link>
            )}
        </div>
    );
};

const HomeAppContainer = (props) => {
    return <div className="home-app-container">{props.children}</div>;
};

const HomepageContainer = () => {
    const dispatch = useDispatch();
    const highlightRepo =
        EntityRepository().getRepository(HIGHLIGHT_REPOSITORY);

    const [popupVisible, setPopupVisible] = useState(
        !localStorage.getItem("cybersecurityPopupClosed")
    );

    const me = useSelector((state) => state.user.me);
    const [isUnitedHeroesPopUpHidden, setUnitedHeroesPopUpHidden] = useState(
        localStorage.getItem("isUnitedHeroesPopUpHidden")
            ? localStorage.getItem("isUnitedHeroesPopUpHidden")
            : false
    );
    // const spconfig = {
    //     public_key:
    //         "33b13037b632b092fc2d21d25cd1f1b8ebaade99ef51135594f862f70300",
    //     debug: false,
    //     set_cookie: true,
    // };

    const loadSpreadTracker = () => {
        window.domLoadEventFired = !0;
        let a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.charset = "UTF-8";
        a.id = "spread-tracker";
        a.src = "//static-sb.com/js/sb-tracker.js";
        document.body.appendChild(a);
    };

    useEffect(() => {
        if (!me.loaded) {
            dispatch(setAppLoading(true));
            dispatch(UserActions.fetchCurrentUser());
        }
        dispatch(NewsActions.fetchHighlight());
    }, []);

    useEffect(() => {
        // Méthode de chargement du tracker
        if (typeof me !== "undefined") {
            if (me.type === "consultant" || me.type === "internal") {
                loadSpreadTracker();
            }
        }

        if (
            moment() >= moment("2022-02-07", "YYYY-MM-DD") &&
            moment() < moment("2022-02-21", "YYYY-MM-DD")
        ) {
            if (me.loaded && popupVisible) {
                changeOverlayContent(
                    <CyberPopUp setPopupVisible={setPopupVisible} />
                );
            }
        }
    }, [me]);

    const openCyberPopup = () => {
        changeOverlayContent(<CyberPopUp setPopupVisible={setPopupVisible} />);
        setPopupVisible(true);
        localStorage.removeItem("cybersecurityPopupClosed");
    };

    const homepagePost = highlightRepo.findLast();

    return (
        me.loaded && (
            <div id="page-container" className="home-page">
                <div className="homepage-container">
                    {moment() >= moment("2022-02-07", "YYYY-MM-DD") &&
                        moment() < moment("2022-02-21", "YYYY-MM-DD") && (
                            <div
                                onClick={openCyberPopup}
                                id="open-cyber-popup-link"
                            ></div>
                        )}
                    <div className="container">
                        <div className="header-container">
                            <h1>Que voulez-vous faire aujourd’hui ?</h1>
                        </div>
                        <div className="home-apps">
                            <HomeAppContainer>
                                <HomeApp button="Mes Actualités" url="/actus">
                                    {homepagePost && (
                                        <HomepagePost post={homepagePost} />
                                    )}
                                </HomeApp>
                            </HomeAppContainer>
                            <HomeAppContainer>
                                <HomeApp
                                    button="Mon Activité"
                                    url="/timesheet"
                                    bgColor="#007288"
                                    color="#fff"
                                    className={
                                        "my-activity-app" +
                                        (me.type === "contractor"
                                            ? " full-activity"
                                            : "")
                                    }
                                    pastille={true}
                                >
                                    <i className="fal fa-calendar-edit"></i>
                                    {me.type !== "contractor" ? (
                                        <Fragment>
                                            Je gère mes absences <br />
                                            et mon CRA
                                        </Fragment>
                                    ) : (
                                        <Fragment>Je gère mon CRA</Fragment>
                                    )}
                                </HomeApp>
                                {me.type !== "contractor" ? (
                                    <HomeApp
                                        button="Expensya"
                                        url="https://www.expensya.com/Portal/#/Login?lang=fr"
                                        external="true"
                                        bgColor="#8bc53f"
                                        color="#fff"
                                    >
                                        <i className="fal fa-file-invoice-dollar"></i>
                                        Je déclare mes frais
                                    </HomeApp>
                                ) : (
                                    ""
                                )}
                            </HomeAppContainer>
                            <HomeAppContainer>
                                <HomeApp
                                    button="Mes Contacts"
                                    url={getContactDefaultRoute(me)}
                                    bgColor="#a51160"
                                    color="#fff"
                                >
                                    <i className="fal fa-user-friends"></i>
                                    Qui sont mes interlocuteurs ?
                                </HomeApp>
                                {me.type !== "contractor" ? (
                                    <HomeApp
                                        button={[
                                            "Mes documents",
                                            "Mes informations sociales",
                                        ]}
                                        url={[
                                            "/documents",
                                            "/documents/social",
                                        ]}
                                        bgColor="#26B19E"
                                        color="#fff"
                                        className="documents-app"
                                    >
                                        <i className="fal fa-folders"></i>
                                        <span id="documents-app-content">
                                            Je m'informe
                                        </span>
                                    </HomeApp>
                                ) : (
                                    ""
                                )}
                            </HomeAppContainer>
                        </div>
                        <Disclaimer />
                    </div>
                </div>
                <Disclaimer />
            </div>
        )
    );
};

export default HomepageContainer;
