import moment from "moment";
import "moment/locale/fr";

class Day {
    id;
    dateAt;
    am;
    pm;
    worked;
    workingState;

    constructor(json) {
        this.id = json.id;
        this.dateAt = json.date_at;
        this.am = json.am;
        this.pm = json.pm;
        this.worked = json.worked;
        this.workingState = json.working_state;
    }

    get DayNumber() {
        return moment.parseZone(this.dateAt).date();
    }
}

export default Day;
