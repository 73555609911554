import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as UserActions from "actions/User";
import RunAsUser from "./RunAsUser";
import usePressEnterKey from "hooks/usePressEnterKey";
import usePressNumPadEnterKey from "hooks/usePressNumPadEnterKey";

import SearchBar from "components/ContactContainer/SearchBar";
import { sanitizeForSearch } from "utils/Utils";
import { setAppLoading } from "actions/Common";

import "./RunAsContainer.scss";
import { removeCache, removeExpirableCache } from "utils/LocalStorage";

const RunAsContainer = () => {
    const [filter, setFilter] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const me = useSelector((state) => state.user.me);
    const users = useSelector((state) => state.user.runAsListUsers);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(UserActions.fetchRunAsList());
    }, []);

    useEffect(() => {
        setFilteredUsers(filterUsers());
    }, [filter, users]);

    const runAsUser = (username) => {
        localStorage.setItem("switchUser", username);
        removeCache("userForAbsenceList");
        removeCache("userForCRAList");
        removeCache("userForAbsenceListFetchedAt");
        removeCache("userForCRAListFetchedAt");
        removeExpirableCache("overtimeCodes");
        removeExpirableCache("eventCodes");
        dispatch(setAppLoading(true));
        me.loaded = false;
        dispatch(UserActions.fetchCurrentUser());
        window.location = "/";
    };

    usePressEnterKey(() => {
        if (filteredUsers.length === 1) {
            runAsUser(filteredUsers[0].username);
        }
    });

    usePressNumPadEnterKey(() => {
        if (filteredUsers.length === 1) {
            runAsUser(filteredUsers[0].username);
        }
    });

    const filterUsers = () => {
        let filtered = users;

        if (filter) {
            return filtered.filter((element) => {
                let lowercasedFilter = sanitizeForSearch(filter);
                if (
                    sanitizeForSearch(element.firstname).includes(
                        lowercasedFilter
                    ) ||
                    sanitizeForSearch(element.lastname).includes(
                        lowercasedFilter
                    ) ||
                    sanitizeForSearch(
                        element.firstname + " " + element.lastname
                    ).includes(lowercasedFilter)
                ) {
                    return element;
                }
            });
        }

        return filtered;
    };

    return (
        <div id="page-container">
            <h1>Se connecter en tant que</h1>
            <div className="container">
                <SearchBar
                    setFilter={setFilter}
                    placeholder="Rechercher un collaborateur"
                />

                {filter.length > 2 || filteredUsers.length <= 10 ? (
                    filteredUsers.map((element, key) => {
                        return (
                            <RunAsUser
                                user={element}
                                key={key}
                                handleClick={runAsUser}
                            />
                        );
                    })
                ) : (
                    <div className="empty-runas">
                        Commencez à taper pour rechercher un utilisateur
                    </div>
                )}
                {/* {users.map((element, key) => {
                    return <RunAsUser user={element} key={key} />;
                })} */}
            </div>
        </div>
    );
};

export default RunAsContainer;
