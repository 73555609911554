import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./EventWidget.scss";

const EventWidget = (props) => {
    const post = props.post;

    return (
        <div className="event-post">
            <div className="event-post-front">
                <div className="event-post-header">
                    <i className="far fa-fw fa-calendar"></i> Agenda
                </div>
                <div className="event-post-title">{post.title}</div>
                <div className="event-post-date">
                    <i className="far fa-fw fa-calendar"></i>{" "}
                    {moment.parseZone(post.dateAt).format("D/MM/YYYY")}
                </div>
                <div className="event-post-date">
                    <i className="far fa-fw fa-flip-horizontal fa-clock"></i>{" "}
                    {moment.parseZone(post.dateAt).format("H[h]mm")}
                </div>
                <div className="event-post-date">
                    <i className="fas fa-fw fa-map-marker-alt"></i> {post.place}
                </div>
            </div>
            <div className="event-post-back">
                <img src={post.picture} alt={post.title} />
                <div className="event-post-content">{post.summary}</div>
            </div>
        </div>
    );
};

export default EventWidget;
