class ContactGroup {
    constructor(json) {
        this.id = json.id;
        this.phone = json.phone;
        this.mobile = json.mobile;
        this.email = json.email;
        this.title = json.title;
        this.text = json.text;
        this.contacts = json.contacts;
    }
}

export default ContactGroup;
