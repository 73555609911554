class EventCode {
    id;
    type;
    code;
    description;
    priority;
    color;
    startAt;
    endAt;
    editable;
    shortCode;
    relevantCounter;

    constructor(json) {
        this.id = json.id;
        this.type = json.type;
        this.code = json.code;
        this.description = json.description;
        this.priority = json.priority;
        this.color = json.color;
        this.startAt = json.start_at;
        this.endAt = json.end_at;
        this.editable = json.editable;
        this.shortCode = json.short_code;
        this.relevantCounter = json.relevant_counter;
    }

    get label() {
        return this.description;
    }
}

export default EventCode;
