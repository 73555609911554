import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeOverlayContent } from "events/OverlayEvents";
import FileRow from "components/DocumentContainer/FileRow";
import WelcomeBook from "entities/WelcomeBook";
import { default as FolderEntity } from "entities/Folder";
import Folder from "components/DocumentContainer/Folder";
import Dropdown from "components/common/Dropdown";
import MiniLoader from "components/common/MiniLoader";
import ConfirmLightbox from "components/common/ConfirmLightbox";
import FileDepositLightbox from "components/Backend/DocumentContainer/FileDepositLightbox";
import ItemLightbox from "components/Backend/DocumentContainer/ItemLightbox";
import * as DocumentActions from "actions/Document";
import "./FilesContainer.scss";

const FilesContainer = (props) => {
    const dispatch = useDispatch();
    const { files, title, isEdit, categoryId, unionId, type } = props;

    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div className="files-container">
            {title && (
                <div className="files-title">
                    {title}{" "}
                    {isEdit && (
                        <Dropdown
                            isDropdownVisible={isDropdownVisible}
                            setDropdownVisible={setDropdownVisible}
                        >
                            <ul className="dropdown-list">
                                {type !== "union" ? (
                                    <>
                                        <li
                                            onClick={() => {
                                                setDropdownVisible(false);
                                                changeOverlayContent(
                                                    <FileDepositLightbox
                                                        categoryId={categoryId}
                                                    />
                                                );
                                            }}
                                        >
                                            Ajouter un fichier à cette catégorie
                                        </li>
                                        <li
                                            onClick={() => {
                                                setDropdownVisible(false);
                                                changeOverlayContent(
                                                    <ItemLightbox
                                                        categoryId={categoryId}
                                                        mode="create"
                                                    />
                                                );
                                            }}
                                        >
                                            Ajouter un dossier à cette catégorie
                                        </li>
                                        <li
                                            className={
                                                files.length > 0
                                                    ? "disabled"
                                                    : ""
                                            }
                                            onClick={() => {
                                                if (files.length === 0) {
                                                    setDropdownVisible(false);
                                                    changeOverlayContent(
                                                        <ConfirmLightbox
                                                            title={
                                                                "Supprimer une catégorie"
                                                            }
                                                            message={`Êtes-vous sûr de vouloir supprimer la catégorie "${title}" ?`}
                                                            handleSubmit={() =>
                                                                dispatch(
                                                                    DocumentActions.deleteCategory(
                                                                        categoryId
                                                                    )
                                                                ).then(() => {
                                                                    changeOverlayContent(
                                                                        null
                                                                    );
                                                                    dispatch(
                                                                        DocumentActions.fetchDocuments()
                                                                    );
                                                                    dispatch(
                                                                        DocumentActions.fetchUnions()
                                                                    );
                                                                })
                                                            }
                                                        />
                                                    );
                                                }
                                            }}
                                        >
                                            Supprimer cette catégorie
                                        </li>
                                    </>
                                ) : (
                                    <li
                                        onClick={() => {
                                            setDropdownVisible(false);
                                            changeOverlayContent(
                                                <FileDepositLightbox
                                                    unionId={unionId}
                                                    type={type}
                                                />
                                            );
                                        }}
                                    >
                                        Ajouter un fichier
                                    </li>
                                )}
                            </ul>
                        </Dropdown>
                    )}
                </div>
            )}
            <div className="files-container-content">
                {files?.length > 1 ? (
                    files.map((file, key) => {
                        if (file?.files) {
                            return (
                                <Folder
                                    categoryId={categoryId}
                                    folder={file}
                                    key={key}
                                    isEdit={isEdit}
                                />
                            );
                        } else {
                            return (
                                <FileRow
                                    categoryId={categoryId}
                                    file={file}
                                    key={key}
                                    isWelcomeBook={files instanceof WelcomeBook}
                                    visible={true}
                                    isEdit={isEdit}
                                    type={type}
                                />
                            );
                        }
                    })
                ) : files !== undefined ? (
                    files.length > 0 ? (
                        Object.values(files).map((file, key) => {
                            return file instanceof FolderEntity ? (
                                <Folder
                                    categoryId={categoryId}
                                    folder={file}
                                    key={key}
                                    isEdit={isEdit}
                                />
                            ) : (
                                <FileRow
                                    categoryId={categoryId}
                                    file={file}
                                    key={key}
                                    visible={true}
                                    isEdit={isEdit}
                                    type={type}
                                />
                            );
                        })
                    ) : files instanceof WelcomeBook ? (
                        <FileRow
                            file={files}
                            categoryId={categoryId}
                            isWelcomeBook={files instanceof WelcomeBook}
                            visible={true}
                            isEdit={isEdit}
                            type={type}
                        />
                    ) : (
                        <div className="empty-unions-files">
                            Aucun fichier disponible
                        </div>
                    )
                ) : (
                    <div className="empty-unions-files">
                        <MiniLoader
                            color={"#00c1de"}
                            borderWidth="3px"
                            containerWidth="80px"
                            containerHeight="80px"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilesContainer;
