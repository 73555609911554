import React from "react";
import { Link } from "react-router-dom";

import "./TwitterWidget.scss";

const TwitterWidget = (props) => {
    const post = props.post;

    return (
        <div className="container-twitter">
            <div className="img-twitter">
                {post.homepageImageUrl && (
                    <a
                        href={post.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={post.homepagePicture} alt={post.title} />
                    </a>
                )}
            </div>
            <div className="content-center">
                <div className="link-twitter">
                    <img
                        className="synchrone-img"
                        src="img/synchrone.png"
                        alt="synchrone_image"
                    />
                    <div className="link-twitter-synchrone">
                        <span className="span-synchrone">
                            <a
                                href="https://twitter.com/SynchroneFr"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Synchrone
                            </a>
                        </span>
                        <span className="span-synchrone-fr">@SynchroneFr</span>
                    </div>
                    <div className="twitter-logo">
                        <a
                            href={post.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={"img/Twitter_Bird.svg"}
                                alt="twitter_logo"
                            />
                        </a>
                    </div>
                </div>
                <div
                    className="content-twitter"
                    dangerouslySetInnerHTML={{
                        __html: post.homepageContent,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default TwitterWidget;
