import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./Disclaimer.scss";
import { Link } from "react-router-dom";

const Disclaimer = (props) => {
    return (
        <div
            id="disclaimer"
            className={
                props.login
                    ? "login-disclaimer"
                    : props.gdpr
                    ? "gdpr-disclaimer"
                    : ""
            }
        >
            © Synchrone - {moment().format("YYYY")} •{" "}
            {/* Mentions légales •{" "} */}
            <Link to="/rgpd">Mentions RGPD</Link>
        </div>
    );
};

export default Disclaimer;
