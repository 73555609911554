import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import CalendarHeader from "components/TimesheetContainer/Calendar/CalendarHeader";
import DaysContainer from "components/TimesheetContainer/Calendar/DaysContainer";
import DaysOfWeek from "components/TimesheetContainer/Calendar/DaysOfWeek";
import Edit from "components/TimesheetContainer/Forms/Event/Edit";
import usePressEscapeKey from "hooks/usePressEscapeKey";

import * as TimesheetActions from "actions/Timesheet";
import { changeOverlayContent } from "events/OverlayEvents";
import SendMonthButton from "components/TimesheetContainer/Calendar/SendMonthButton";
import SentAt from "components/TimesheetContainer/Calendar/SentAt";
import DownloadCRA from "./DownloadCRA";

import "./Calendar.scss";
import DepositSpecificCRAButton from "./DepositSpecificCRAButton";

const Calendar = (props) => {
    const dispatch = useDispatch();
    // const isLoading = useSelector(state => state.common.isLoading);

    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const selectedEndDay = useSelector(
        (state) => state.timesheet.selectedEndDay
    );

    const { days, me, monthEntity, type, user } = props;

    usePressEscapeKey(() => {
        if (selectedStartDay && !selectedEndDay) {
            dispatch(TimesheetActions.clearDaysSelection());
            dispatch(TimesheetActions.clearDaysHoveredSelection());
        }
    });

    useEffect(() => {
        if (selectedEndDay) {
            let iterableDays = Object.values(days);
            let daysToAdd = [];
            iterableDays.forEach((element) => {
                if (element.worked) {
                    if (
                        moment.parseZone(element.date_at) >
                            selectedStartDay.date &&
                        moment.parseZone(element.date_at) < selectedEndDay.date
                    ) {
                        daysToAdd.push(element.id);
                    }
                }
            });
            dispatch(TimesheetActions.selectDays(daysToAdd));
            changeOverlayContent(
                <Edit action="new" monthEntity={monthEntity} />
            );
        }
    }, [selectedEndDay]);

    const handleClick = () => {
        changeOverlayContent(<DownloadCRA monthEntity={monthEntity} />);
    };

    if (monthEntity.loaded) {
        return (
            <div>
                <div
                    className={
                        (type === "view" ? "view-only " : "") +
                        "calendar-container"
                    }
                >
                    <CalendarHeader
                        monthEntity={monthEntity}
                        type={type}
                        user={user}
                    />
                    <DaysOfWeek />
                    <DaysContainer
                        type={type}
                        monthEntity={monthEntity}
                        days={days}
                        me={me}
                        mainActivity={
                            monthEntity.mainActivity
                                ? monthEntity.mainActivity
                                : null
                        }
                        user={user}
                    />
                    {!props.monthEntity.submitted ? (
                        type !== "view" && (
                            <SendMonthButton
                                monthEntity={monthEntity}
                                personType={me.type}
                                hasEvents={monthEntity.events.length > 0}
                            />
                        )
                    ) : (
                        <Fragment>
                            <SentAt submittedAt={monthEntity.submittedAt} />
                            {type !== "view" &&
                                me.type === "consultant" &&
                                !monthEntity.locked && (
                                    <DepositSpecificCRAButton
                                        monthEntity={monthEntity}
                                    />
                                )}
                        </Fragment>
                    )}
                    {type !== "view" && monthEntity.events.length > 0 && (
                        <div className="download-cra" onClick={handleClick}>
                            Télécharger le CRA{" "}
                            <i className="far fa-arrow-to-bottom"></i>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return <div className="calendar-container"></div>;
    }
};

export default Calendar;
