import React, { useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";

import {
    EntityRepository,
    OVERTIME_CODE_REPOSITORY,
} from "store/EntityRepository";
import * as TimesheetActions from "actions/Timesheet";
import { useSelector, useDispatch } from "react-redux";
import "./Availability.scss";
import DeleteOvertime from "components/TimesheetContainer/Forms/Overtime/DeleteOvertime";
import { changeOverlayContent } from "events/OverlayEvents";

const Availability = (props) => {
    const {
        entity,
        hovered,
        currentMonth,
        currentYear,
        firstDayOfMonth,
        isMonthEditable,
        type,
        expandedDisplay,
    } = props;
    const dispatch = useDispatch();
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const overtimeCodeRepo = EntityRepository().getRepository(
        OVERTIME_CODE_REPOSITORY
    );
    const overtimeCode = overtimeCodeRepo.find(entity.code, type);

    const getStartDateForMonth = () => {
        let startAtForThisMonth = moment.parseZone(props.entity.startAt);
        while (startAtForThisMonth.month() + 1 < currentMonth) {
            startAtForThisMonth.add(1, "days");
        }

        return startAtForThisMonth;
    };

    const getEndDateForMonth = () => {
        let endAtForThisMonth = moment.parseZone(props.entity.endAt);
        while (endAtForThisMonth.month() + 1 > currentMonth) {
            endAtForThisMonth.subtract(1, "days");
        }

        return endAtForThisMonth;
    };

    const startAt = getStartDateForMonth();
    const endAt = getEndDateForMonth();

    const getEventWidth = () => {
        if (expandedDisplay) {
            return "initial";
        }

        let width =
            (1 + endAt.weekday() - startAt.weekday()) * 169 +
            (endAt.weekday() - startAt.weekday()) * 2;

        if (props.entity.startMoment === "pm") {
            width -= 169 / 2;
        }

        if (props.entity.endMoment === "am") {
            width -= 169 / 2;
        }

        return width + "px";
    };

    const getLeftPosition = () => {
        if (expandedDisplay) {
            return "initial";
        }

        return (
            startAt.weekday() * 171 +
            2 +
            (props.entity.startMoment === "pm" ? 169 / 2 : 0) +
            "px"
        );
    };

    const getTopPosition = () => {
        if (expandedDisplay) {
            return "initial";
        }

        if (hovered) {
            return (
                Math.ceil(
                    (startAt.date() + firstDayOfMonth.weekday()) / 7 - 1
                ) *
                    122 +
                98 +
                "px"
            );
        }

        return (
            Math.ceil((startAt.date() + firstDayOfMonth.weekday()) / 7 - 1) *
                122 +
            108 +
            "px"
        );
    };

    const hoverEvent = (hovered) => {
        if (type !== "view") {
            if (entity && !selectedStartDay) {
                if (
                    entity.id &&
                    entity.state !== "validated1" &&
                    entity.state !== "validated2"
                ) {
                    if (hovered) {
                        dispatch(TimesheetActions.hoverOvertime(entity.id));
                    } else {
                        dispatch(TimesheetActions.hoverOvertime(null));
                    }
                }
            }
        }
    };

    const overtimeStyles = {
        position: !expandedDisplay ? "absolute" : "initial",
        backgroundColor: "#fdbc32",
        // width: "342px",
        // left: "1px",
        // top: "474px",
        width: getEventWidth(),
        left: getLeftPosition(),
        top: getTopPosition(),
        height: "15px",
        lineHeight: "15px",
        color: "white",
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: "600",
        paddingLeft: "15px",
        cursor: "pointer",
    };

    if (hovered) {
        overtimeStyles.height = "25px";
    }

    const handleClick = () => {
        if (type !== "view") {
            if (isMonthEditable) {
                changeOverlayContent(
                    <DeleteOvertime
                        entity={entity}
                        overtimeCode={overtimeCode}
                        currentMonth={currentMonth}
                        currentYear={currentYear}
                    />
                );
            }
        }
    };

    return (
        <div
            className={"overtime" + (type === "view" ? " view-only" : "")}
            style={overtimeStyles}
            onMouseEnter={() => hoverEvent(true)}
            onMouseLeave={() => hoverEvent(false)}
            onClick={() => handleClick()}
            title={overtimeCode && overtimeCode.description}
        >
            <div style={{ fontWeight: "900" }} className="overtime-count bold">
                {entity.constraintCount > 0
                    ? "(" + entity.constraintCount + " A) "
                    : "(" +
                      Math.floor(entity.hourCount / 60) +
                      "H" +
                      (entity.hourCount % 60 > 0 ? entity.hourCount % 60 : "") +
                      ") "}
                •
            </div>
            <div className="overtime-description">
                {overtimeCode && overtimeCode.description}
            </div>
        </div>
    );
};

export default Availability;
