import * as Yup from "yup";

const allowedSpecialCharacters =
    /([\/\".;:~&{\(\[|_@\)\]}\$£%!\?#\^\*\-\+\=\\])/;

export const minLengthSchema = Yup.object().shape({
    password: Yup.string().min(8),
});

export const atLeastOneLowerSchema = Yup.object().shape({
    password: Yup.string().matches(/([a-z])/),
});

export const atLeastOneUpperSchema = Yup.object().shape({
    password: Yup.string().matches(/([A-Z])/),
});

export const atLeastOneSpecialSchema = Yup.object().shape({
    password: Yup.string().matches(allowedSpecialCharacters),
});

export const atLeastOneNumberSchema = Yup.object().shape({
    password: Yup.string().matches(/([0-9])/),
});

export const passwordValidSchema = Yup.object().shape({
    password: Yup.string()
        .min(8)
        .test(
            "atLeastThreeOf",
            "Votre mot de passe doit contenir au moins 3 des critères",
            (item) => {
                let okTests = 0;
                if (item.match(/([0-9])/)) {
                    okTests++;
                }
                if (item.match(allowedSpecialCharacters)) {
                    okTests++;
                }
                if (item.match(/([A-Z])/)) {
                    okTests++;
                }
                if (item.match(/([a-z])/)) {
                    okTests++;
                }
                if (okTests >= 3) {
                    return true;
                }
                return false;
            }
        ),
});

export const confirmPasswordSchema = Yup.object().shape({
    confirmPassword: Yup.string()
        .ensure()
        .required("Veuillez renseigner un mot de passe")
        .oneOf([
            Yup.ref("password"),
            // null,
            "Les champs doivent correspondre",
        ]),
});

export const completePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .ensure()
        .required("Veuillez renseigner un mot de passe")
        .min(8, "Votre mot de passe doit contenir au moins 8 caractères")
        .test(
            "atLeastThreeOf",
            "Votre mot de passe doit contenir au moins 3 des critères",
            (item) => {
                let okTests = 0;
                if (item.match(/([0-9])/)) {
                    okTests++;
                }
                if (item.match(allowedSpecialCharacters)) {
                    okTests++;
                }
                if (item.match(/([A-Z])/)) {
                    okTests++;
                }
                if (item.match(/([a-z])/)) {
                    okTests++;
                }
                if (okTests >= 3) {
                    return true;
                }
                return false;
            }
        ),
    confirmPassword: Yup.string()
        .ensure()
        .required("Veuillez renseigner un mot de passe")
        .oneOf([Yup.ref("password"), null, "Les champs doivent correspondre"]),
});
