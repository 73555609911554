import React from "react";

import "./OvertimeBlock.scss";
import { changeOverlayContent } from "events/OverlayEvents";
import OvertimeDetails from "components/Backend/CRAContainer/OvertimeDetails";

const OvertimeBlock = (props) => {
    const { me, month, user, manager, period } = props;

    return (
        <div className="overtime-block">
            {month.intervention ? (
                <i
                    className="intervention-icon fal fa-watch"
                    title="Heures supplémentaires"
                ></i>
            ) : (
                ""
            )}

            {month.constraint ? (
                <div className="availability-icon" title="Astreintes">
                    A
                </div>
            ) : (
                ""
            )}
            {month.constraint || month.intervention ? (
                <div
                    className="overtime-details"
                    onClick={() =>
                        changeOverlayContent(
                            <OvertimeDetails
                                user={user}
                                manager={manager}
                                period={period}
                                me={me}
                            />
                        )
                    }
                >
                    Détail
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default OvertimeBlock;
