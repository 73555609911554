import React from "react";
import { Link } from "react-router-dom";

import "./InterviewWidget.scss";

const InterviewWidget = (props) => {
    const post = props.post;

    return (
        <Link className="container-interview" to={`/actus/${post.id}`}>
            <div className="interview-title">Interview</div>
            {post.homepageImageUrl && (
                <img src={post.homepagePicture} alt={post.title} />
            )}
            <div className="interview-content">{post.title}</div>
            <div className="interview-summary">{post.summary}</div>
        </Link>
    );
};

export default InterviewWidget;
