import React from "react";

import "./PersonTypeBadge.scss";

const PersonTypeBadge = (props) => {
    const { personType } = props;

    let personTypeLabel, badgeClass;

    switch (personType) {
        case "internal":
            personTypeLabel = "Structure Interne";
            badgeClass = "two-liner";
            break;
        case "contractor":
        case "consultant":
            personTypeLabel = "Consultant";
            badgeClass = "one-liner";
            break;

        default:
            break;
    }

    return (
        <div className={"person-type-badge " + badgeClass}>
            {personTypeLabel}
        </div>
    );
};

export default PersonTypeBadge;
