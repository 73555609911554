import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import "moment/locale/fr";

import Lightbox from "components/common/Lightbox";
import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";

import * as ValidationActions from "actions/Validation";
import * as TimesheetActions from "actions/Timesheet";

import { changeOverlayContent } from "events/OverlayEvents";

import "./ActivityEditType.scss";

const ActivityEditType = (props) => {
    const { user, manager, month, period } = props;
    const eventsForValidation = useSelector((state) => state.validation.events);
    const dispatch = useDispatch();

    let activityCount = 0,
        absenceCount = 0;

    Object.values(month.events).map((event) => {
        if (event.days > 0) {
            if (event.type === "absence") {
                absenceCount += event.days;
            } else {
                activityCount += event.days;
            }
        }
    });

    const loadList = () => {
        dispatch(
            ValidationActions.fetchEventForValidationAction(
                user.matricule,
                moment.parseZone(period).format("YYYY-MM")
            )
        );
    };

    useEffect(() => {
        loadList();
    }, []);

    const close = () => {
        changeOverlayContent(null);
        loadList();
    };

    const patchActivity = (eventId, comment, selectedNewEventCode) => {
        let json = {
            comment: comment,
            event_code: selectedNewEventCode,
        };
        let body = JSON.stringify(json);
        dispatch(
            ValidationActions.changeActivityTypeAction(eventId, body)
        ).then(() => {
            loadList();
            dispatch(TimesheetActions.notifySuccess("Modification effectuée"));
        });
    };

    const ActivityLine = (props) => {
        const { event } = props;
        const [editMode, setEditMode] = useState(false);
        const [comment, setComment] = useState("");
        const [selectedNewEventCode, setSelectedNewEventCode] = useState(
            event.eventCode.id
        );
        const [allowedEventCodes, setAllowedEventCodes] = useState([]);

        useEffect(() => {
            dispatch(
                ValidationActions.fetchEventCodesForEditAction(event.id)
            ).then((response) => {
                let tempArray = [];
                Object.values(response.response.entities.eventCodes).map(
                    (eventCode, key) => {
                        tempArray.push({
                            value: eventCode.id,
                            label: eventCode.description,
                        });
                    }
                );
                setAllowedEventCodes(tempArray);
            });
        }, []);

        const handleCommentChange = (e) => {
            setComment(e.target.value);
        };

        return (
            <div className="activity-line">
                {!editMode ? (
                    <div className="activity-line-description">
                        {event.eventCode.description}
                    </div>
                ) : (
                    <div className="activity-edit-label">
                        <Select
                            className="activity-edit-type"
                            classNamePrefix="activity-edit-type"
                            name="activity-code"
                            value={
                                allowedEventCodes
                                    ? allowedEventCodes.find((option) => {
                                          return (
                                              option.value ===
                                              selectedNewEventCode
                                          );
                                      })
                                    : ""
                            }
                            isOptionSelected={(selOpt) => {
                                return selOpt === selectedNewEventCode;
                            }}
                            options={allowedEventCodes}
                            onChange={(option) =>
                                setSelectedNewEventCode(option.value)
                            }
                            placeholder="Chargement..."
                        />
                    </div>
                )}

                <div className="activity-line-days-range">
                    {moment.parseZone(event.startAt).format("DD/MM/YYYY")} -{" "}
                    {moment.parseZone(event.endAt).format("DD/MM/YYYY")}
                </div>
                <div className="activity-line-total-days">
                    {event.daysCount}
                </div>
                <div className="activity-line-actions">
                    {!editMode ? (
                        <i
                            className="far edit-activity-icon fa-edit"
                            onClick={() => setEditMode(true)}
                        ></i>
                    ) : (
                        <Fragment>
                            <i
                                className="fas fa-times close-edit-activity"
                                onClick={() => setEditMode(false)}
                            ></i>
                            <i
                                className="fas fa-check-square validate-edit-activity"
                                onClick={() => {
                                    patchActivity(
                                        event.id,
                                        comment,
                                        selectedNewEventCode
                                    );
                                }}
                            ></i>
                        </Fragment>
                    )}
                </div>
                {editMode ? (
                    <div>
                        <div className="activity-validation-comment-label">
                            Commentaire valideur (interne)
                        </div>
                        <textarea
                            className="activity-validation-comment"
                            onChange={(e) => handleCommentChange(e)}
                        ></textarea>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    return (
        <Lightbox className="lb-centered validate-activity-lb">
            <div className="validate-activity-header">
                <div className="validate-activity-title">
                    Modifier le libellé d'une activité
                </div>
                <div className="validate-activity-identity-block">
                    <div className="validate-activity-identity">
                        {user.identity}
                    </div>
                    <PersonTypeBadge personType={user.type} />
                    <div className="validate-activity-matricule">
                        {user.matricule}
                    </div>
                    <div className="validate-activity-manager">
                        Responsable : {manager && manager.identity}
                    </div>
                </div>
            </div>

            <div className="validate-activity-period">
                {period.format("MMMM YYYY")}
            </div>

            <div className="validate-activity-counts">
                <div className="activity-count">
                    Activités : <span className="count">{activityCount} J</span>
                </div>
                <div className="absence-count">
                    Absences : <span className="count">{absenceCount} J</span>
                </div>
                <div className="total-count-information">
                    Total :{" "}
                    <div className="total-count">
                        {absenceCount + activityCount} J
                    </div>
                </div>
            </div>

            <div className="activity-container">
                <div className="activity-header">
                    <div className="header-label">Activité</div>
                    <div className="header-days-range">Jours</div>
                    <div className="header-total-days">Total jours</div>
                </div>
                <div className="activity-lines">
                    {Object.values(eventsForValidation).map((event, key) => {
                        if (event.eventCode.type !== "absence") {
                            return <ActivityLine key={key} event={event} />;
                        }
                    })}
                </div>
            </div>

            <div className="validate-activity-actions">
                <div className="cancel-btn" onClick={close}>
                    Fermer
                </div>
            </div>
        </Lightbox>
    );
};

export default ActivityEditType;
