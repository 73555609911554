import React from "react";
import RexWidget from "components/HomepageContainer/Widget/RexWidget";
import PressWidget from "components/HomepageContainer/Widget/PressWidget";
import EventWidget from "components/HomepageContainer/Widget/EventWidget";
import ZoomWidget from "components/HomepageContainer/Widget/ZoomWidget";
import InterviewWidget from "components/HomepageContainer/Widget/InterviewWidget";
import TwitterWidget from "components/HomepageContainer/Widget/TwitterWidget";
import FacebookWidget from "components/HomepageContainer/Widget/FacebookWidget";
import InstagramWidget from "components/HomepageContainer/Widget/InstagramWidget";
import LinkedinWidget from "components/HomepageContainer/Widget/LinkedinWidget";

import "./HomepagePost.scss";

const HomepagePost = (props) => {
    const post = props.post;

    return (
        <div className="home-news-container">
            {
                {
                    1: <RexWidget post={post} />,
                    2: <PressWidget post={post} />,
                    3: <EventWidget post={post} />,
                    4: <ZoomWidget post={post} />,
                    5: <InterviewWidget post={post} />,
                    6: <TwitterWidget post={post} />,
                    7: <FacebookWidget post={post} />,
                    8: <InstagramWidget post={post} />,
                    9: <LinkedinWidget post={post} />,
                }[post.type]
            }
        </div>
    );
};

export default HomepagePost;
