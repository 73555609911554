import React from "react";
import RexWidget from "components/NewsContainer/Widget/RexWidget";
import PressWidget from "components/NewsContainer/Widget/PressWidget";
import EventWidget from "components/NewsContainer/Widget/EventWidget";
import ZoomWidget from "components/NewsContainer/Widget/ZoomWidget";
import InterviewWidget from "components/NewsContainer/Widget/InterviewWidget";
import TwitterWidget from "components/NewsContainer/Widget/TwitterWidget";
import FacebookWidget from "components/NewsContainer/Widget/FacebookWidget";
import InstagramWidget from "components/NewsContainer/Widget/InstagramWidget";
import LinkedinWidget from "components/NewsContainer/Widget/LinkedinWidget";

import "./PostWidget.scss";

const PostWidget = (props) => {
    const post = props.post;

    return (
        <div className="news-article">
            {{
                1: <RexWidget post={post} />,
                2: <PressWidget post={post} />,
                3: <EventWidget post={post} />,
                4: <ZoomWidget post={post} />,
                5: <InterviewWidget post={post} />,
                6: <TwitterWidget post={post} />,
                7: <FacebookWidget post={post} />,
                8: <InstagramWidget post={post} />,
                9: <LinkedinWidget post={post} />,
            }[post.type] || post.title}
        </div>
    );
};

export default React.memo(PostWidget);
