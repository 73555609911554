import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./VacationCountHistory.scss";

import * as TimesheetActions from "actions/Timesheet";
import { changeOverlayContent } from "events/OverlayEvents";
import AbsenceHistory from "components/TimesheetContainer/Calendar/AbsenceHistory";
import AbsenceStatus from "components/TimesheetContainer/Calendar/AbsenceStatus";
import AbsenceDates from "components/TimesheetContainer/Calendar/AbsenceDates";

import {
    EntityRepository,
    EVENT_CODE_REPOSITORY,
} from "store/EntityRepository";

const VacationCountHistory = () => {
    const dispatch = useDispatch();
    const last3Absences = useSelector((state) => state.user.me.lastAbsences);

    const fetchAbsenceHistory = () => {
        dispatch(TimesheetActions.fetchAbsenceHistory()).then(() => {
            changeOverlayContent(<AbsenceHistory />);
        });
    };

    const HistoryLine = (props) => {
        const event = props.element;
        const eventCodeRepo = EntityRepository().getRepository(
            EVENT_CODE_REPOSITORY
        );
        const eventCode = eventCodeRepo.find(event.eventCode);

        return (
            <div className="history-line">
                <AbsenceStatus event={event} />
                <AbsenceDates event={event} format="D MMM" />
                <span className="total">{event.daysCount} J</span>
                <span
                    className="type"
                    title={eventCode && eventCode.description}
                >
                    {eventCode && eventCode.description}
                </span>
            </div>
        );
    };

    return (
        <div className="vacation-history">
            <div className="vacation-history-header">
                Historique d'absences{" "}
                <i
                    className="far fa-stopwatch"
                    onClick={() => fetchAbsenceHistory()}
                    title="Historique détaillé"
                ></i>
            </div>
            <div className="history">
                {last3Absences.length > 0 ? (
                    <Fragment>
                        <div className="history-header">
                            <div className="history-header-cell dates">
                                Dates
                            </div>
                            <div className="history-header-cell total">
                                Total
                            </div>
                            <div className="history-header-cell type">Type</div>
                        </div>
                        <div className="history-lines-container">
                            {last3Absences.map((element, index) => {
                                return (
                                    <HistoryLine
                                        element={element}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                    </Fragment>
                ) : (
                    <div className="no-absence">Aucune absence à afficher</div>
                )}
            </div>
        </div>
    );
};

export default VacationCountHistory;
