import React from "react";
import { ErrorMessage, Field } from "formik";

import {
    CONSTRAINT_INPUT,
    HOUR_INPUT,
    TICKET_INPUT,
} from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";

const InputType = (props) => {
    const RadioButton = ({
        field: { name, value, onChange, onBlur },
        inputValue,
        label,
        id,
        className,
        handleClick,
        ...props
    }) => {
        return (
            <div className="input-type-radio">
                <label onClick={(e) => handleClick(e, inputValue)} htmlFor={id}>
                    {label}
                </label>
                <input
                    name={name}
                    type="radio"
                    value={inputValue}
                    checked={inputValue === value}
                    onChange={onChange}
                    onBlur={onBlur}
                    id={id}
                    className="radio-button"
                    {...props}
                />
                <div
                    onClick={(e) => handleClick(e, inputValue)}
                    className="fake-radio"
                ></div>
            </div>
        );
    };

    return (
        <div
            className={
                "input-type-radio-group" +
                (props.constraintCategory === "intervention" ? " hidden" : "")
            }
        >
            Saisie :
            <Field
                className="input-type-radio"
                component={RadioButton}
                handleClick={props.handleClick}
                name="type"
                inputValue={CONSTRAINT_INPUT}
                id={"inputType-" + CONSTRAINT_INPUT}
                label="en nombre d'astreintes"
            />
            <Field
                className="input-type-radio"
                component={RadioButton}
                handleClick={props.handleClick}
                name="type"
                inputValue={HOUR_INPUT}
                id={"inputType-" + HOUR_INPUT}
                label="en nombre d'heures"
            />
            <Field
                className="input-type-radio"
                component={RadioButton}
                handleClick={props.handleClick}
                name="type"
                inputValue={TICKET_INPUT}
                id={"inputType-" + TICKET_INPUT}
                label="en tickets"
            />
        </div>
    );
};

export default InputType;
