import React from "react";
import "./VacationCountTotals.scss";

const VacationCountTotals = (props) => {
    return (
        <div className="vacation-totals">
            <div className="vacation-totals-header">
                Mon compteur d'absences
            </div>
            <div className="table">
                <div className="totals">
                    <div className="totals-label">Solde</div>
                    <div className="n-total">
                        <div className="n-total-label">CP</div>
                        <div className="n-total-amount">{props.cp}</div>
                    </div>
                    <div className="rtt-total">
                        <div className="rtt-total-label">RTT</div>
                        <div className="rtt-total-amount">{props.rtt}</div>
                    </div>
                </div>
                <div className="before-june">
                    <div className="before-june-label">
                        A poser avant le 31/05 :
                    </div>
                    <div className="before-june-amount">{props.beforeJune}</div>
                </div>
                {props.remainder > 0 && props.displayRemainderCounter && (
                    <div className="remainder">
                        <div className="remainder-label">Solde Reliquat : </div>
                        <div className="remainder-total">{props.remainder}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VacationCountTotals;
