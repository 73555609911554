import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, useFormikContext, Field, yupToFormErrors } from "formik";
import * as Yup from "yup";

import { monthInLetter } from "utils/Utils";
import { changeOverlayContent } from "events/OverlayEvents";
import Lightbox from "components/common/Lightbox";
import * as TimesheetActions from "actions/Timesheet";
import * as ValidationActions from "actions/Validation";

import {
    EntityRepository,
    EVENT_CODE_REPOSITORY,
} from "store/EntityRepository";

import "./DownloadCRA.scss";

const DownloadCRA = (props) => {
    const dispatch = useDispatch();
    const { monthEntity, targetUser, type } = props;
    const events = useSelector((state) => {
        if (type !== "view") {
            return state.timesheet.entities.month.events;
        }

        return state.validation.craEvents;
    });
    const [eventCodeIds, setEventCodeIds] = useState([]);
    const [eventCodes, setEventCodes] = useState([]);
    const [downloadUrl, setDownloadUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const [isWaitingForDownload, setWaitingForDownload] = useState(false);
    const eventCodeRepo = EntityRepository().getRepository(
        EVENT_CODE_REPOSITORY
    );

    const close = () => {
        changeOverlayContent(null);
    };

    useEffect(() => {
        if (type === "view") {
            setLoading(true);
            Promise.all([
                dispatch(
                    ValidationActions.fetchEventsForCRADisplay(monthEntity.id)
                ),
                dispatch(
                    ValidationActions.fetchEventCodesForCRADisplay(
                        targetUser.matricule
                    )
                ),
            ]).then(() => {
                setLoading(false);
            });

            return () => dispatch(ValidationActions.resetCraDisplay());
        }
    }, []);

    useEffect(() => {
        let groupedEvents = [];
        for (let index = 0; index < events.length; index++) {
            const element = events[index];
            if (groupedEvents.indexOf(element.eventCode) < 0) {
                groupedEvents.push(element.eventCode);
            }
        }
        setEventCodeIds(groupedEvents);
    }, [events]);

    useEffect(() => {
        let eventCodes = [];
        let includeAbsences = false;
        if (eventCodeIds.length > 0) {
            eventCodeIds.map((element, key) => {
                let eventCode;
                if (type === "view") {
                    eventCode = eventCodeRepo.findWithoutHook(element, "view");
                } else {
                    eventCode = eventCodeRepo.findWithoutHook(element);
                }
                if (eventCode?.type !== "absence") {
                    eventCodes.push(eventCode);
                }
            });
        }
        if (includeAbsences) {
            eventCodes.push({});
        }
        setEventCodes(eventCodes);
        if (eventCodeIds.length > 0) {
            setLoading(false);
        }
    }, [eventCodeIds]);

    const EventCodesForm = (props) => {
        let { downloadUrl } = props;

        const { values, isSubmitting, setFieldValue } = useFormikContext();

        const Checkbox = (props) => {
            const { eventCode } = props;
            let value, id, description;

            if (eventCode) {
                value = eventCode.id;
                id = eventCode.id;
                description = eventCode.description;
            } else {
                value = "all";
                id = "all";
                description = "Toutes les activités";
            }

            const handleClick = () => {
                handleChange();
            };

            const handleChange = () => {
                if (values.eventCodes === ["all"]) {
                    setFieldValue("codes", null);
                }
                if (!values.codes.find((element) => element === value)) {
                    let temp;
                    if (values.codes.indexOf("all") > -1) {
                        temp = [value];
                        setFieldValue("codes", temp);
                    } else {
                        temp = [...values.codes, value];
                        setFieldValue("codes", temp);
                    }
                    if (value === "all") {
                        setFieldValue("codes", ["all"]);
                    }
                } else {
                    let temp = [...values.codes];
                    temp.splice(values.codes.indexOf(value), 1);
                    setFieldValue("codes", temp);
                }
            };

            return (
                <div className="activity-row">
                    <Field
                        id={"checkbox-" + id}
                        value={value}
                        type="checkbox"
                        onChange={handleChange}
                        name="codes"
                    />
                    <span onClick={handleClick} className="checkmark"></span>
                    <label
                        className={eventCode ? "" : "extra-bold "}
                        htmlFor={"checkbox-" + id}
                    >
                        {description}
                    </label>
                </div>
            );
        };

        return (
            <Form noValidate>
                {!loading ? (
                    <Fragment>
                        <Checkbox />
                        {eventCodes.map((element, key) => (
                            <Checkbox key={key} eventCode={element} />
                        ))}
                        <button
                            className={
                                "next-btn" +
                                (isWaitingForDownload ? " cancel-btn" : "")
                            }
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Télécharger
                        </button>
                        <a
                            id="hidden-download-button"
                            href={downloadUrl}
                            download
                        ></a>
                    </Fragment>
                ) : (
                    <div className="lds-ripple lds-ripple-inside">
                        <div></div>
                        <div></div>
                    </div>
                )}
            </Form>
        );
    };

    const schema = Yup.object().shape({
        codes: Yup.array()
            .ensure()
            .required("Vous devez choisir au moins 1 activité")
            .min(1, "Vous devez choisir au moins 1 activité")
            .max(eventCodes.length || 1, "Vous avez fourni trop d'arguments"),
    });

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-download-cra">
                <div className="lb-download-cra-header">
                    <div className="download-cra-header-title">
                        Télécharger le CRA du mois
                    </div>
                    {monthEntity && (
                        <div className="download-cra-month-header">
                            <span className="month">
                                {monthInLetter(monthEntity.month)}
                            </span>{" "}
                            <span className="year">{monthEntity.year} </span>
                        </div>
                    )}
                    {isWaitingForDownload ? (
                        <div className="mini-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    ) : (
                        <i className="close-lb fal fa-times" onClick={close} />
                    )}
                </div>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{ codes: ["all"] }}
                    validate={(values, props) => {
                        return schema
                            .validate(values, {
                                abortEarly: false,
                            })
                            .then(() => {
                                return true;
                            })
                            .catch((err) => {
                                dispatch(
                                    TimesheetActions.notifyError(err.errors[0])
                                );
                                return yupToFormErrors(err);
                            });
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        if (targetUser) {
                            values.user = targetUser.matricule;
                        }

                        setWaitingForDownload(true);
                        dispatch(
                            TimesheetActions.downloadCRATimesheet(
                                monthEntity.year,
                                monthEntity.month,
                                values
                            )
                        ).then((response) => {
                            setDownloadUrl(response.response.result);
                            document
                                .getElementById("hidden-download-button")
                                .click();
                            setDownloadUrl("");
                            close();
                            dispatch(
                                TimesheetActions.notifySuccess(
                                    "Téléchargement en cours..."
                                )
                            );
                            setWaitingForDownload(false);
                        });
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 1000);
                    }}
                >
                    {({ errors, isSubmitting }) => (
                        <EventCodesForm downloadUrl={downloadUrl} />
                    )}
                </Formik>
            </div>
        </Lightbox>
    );
};

export default DownloadCRA;
