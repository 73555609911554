import React from "react";
import { Link } from "react-router-dom";

import "./InstagramWidget.scss";

const InstagramWidget = (props) => {
    const post = props.post;

    return (
        <div className="container-instagram">
            <div className="top-content">
                <div className="link-instagram">
                    <img
                        className="synchrone-img"
                        src="img/synchrone.png"
                        alt="synchrone_image"
                    />
                    <div className="link-instagram-synchrone">
                        <span className="span-synchrone">
                            <a
                                href="https://www.instagram.com/bysynchrone/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                bysynchrone
                            </a>
                        </span>
                        <span className="place-synchrone">Paris, France</span>
                    </div>
                </div>
                <i className="fab fa-instagram" aria-hidden="true"></i>
            </div>
            <div className="img-instagram">
                {post.homepageImageUrl && (
                    <a
                        href={post.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={post.homepagePicture} alt={post.title} />
                    </a>
                )}
            </div>
            <div
                className="content-instagram"
                dangerouslySetInnerHTML={{
                    __html: post.homepageContent,
                }}
            ></div>
        </div>
    );
};

export default InstagramWidget;
