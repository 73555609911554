import React from "react";

import {
    EntityRepository,
    EVENT_CODE_REPOSITORY,
} from "store/EntityRepository";

import "./AbsenceHistoryEvent.scss";
import AbsenceStatus from "components/TimesheetContainer/Calendar/AbsenceStatus";
import AbsenceDates from "components/TimesheetContainer/Calendar/AbsenceDates";

const AbsenceHistoryEvent = (props) => {
    const { event, type } = props;

    const eventCodeRepo = EntityRepository().getRepository(
        EVENT_CODE_REPOSITORY
    );
    const eventCode = eventCodeRepo.find(event.eventCode, type);

    return (
        <div className="absence-event-line">
            <div className="absence-year"></div>
            <AbsenceStatus event={event} />
            <AbsenceDates event={event} />
            <div className="absence-total">{event.daysCount} J</div>
            <div className="absence-type">
                {eventCode && eventCode.description}
            </div>
        </div>
    );
};

export default AbsenceHistoryEvent;
