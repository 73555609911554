import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import "moment/locale/fr";

import Lightbox from "components/common/Lightbox";
import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";

import { changeOverlayContent } from "events/OverlayEvents";

import { setLoading } from "actions/Common";
import * as ValidationActions from "actions/Validation";
import * as TimesheetActions from "actions/Timesheet";

import "./OvertimeDetails.scss";
import { daysForOvertimeList } from "utils/Utils";

const OvertimeDetails = (props) => {
    const { me, user, manager, period } = props;
    const dispatch = useDispatch();
    const overtimes = useSelector((state) => state.validation.overtimes);

    const close = () => {
        changeOverlayContent(null);
        dispatch(ValidationActions.resetOvertimesForValidation());
    };

    const loadList = () => {
        dispatch(setLoading(true));
        dispatch(
            ValidationActions.fetchOvertimesForValidation(
                user.matricule,
                period.format("YYYY-MM")
            )
        ).then(() => dispatch(setLoading(false)));
    };

    useEffect(() => {
        loadList();
    }, []);

    const patchOvertime = (overtimeId, comment, selectedNewOvertimeCode) => {
        let json = {
            comment: comment,
            code: selectedNewOvertimeCode,
        };
        let body = JSON.stringify(json);
        dispatch(
            ValidationActions.changeOvertimeTypeAction(overtimeId, body)
        ).then(() => {
            loadList();
            dispatch(TimesheetActions.notifySuccess("Modification effectuée"));
        });
    };

    const durationInHours = (durationInMinutes) => {
        let hoursDuration = "";

        let hour = Math.floor(durationInMinutes / 60);
        let minutes = durationInMinutes - hour * 60;

        hoursDuration = hour + ":" + (minutes < 10 ? "0" : "") + minutes;

        return hoursDuration;
    };

    const availabilities = overtimes.filter(
        (element) => element.code.category === "availability"
    );
    const interventions = overtimes.filter(
        (element) => element.code.category === "intervention"
    );

    const AvailabilityContainer = (props) => {
        const { availabilities } = props;

        const AvailabilityLine = (props) => {
            const { availability } = props;
            const [editMode, setEditMode] = useState(false);
            const [comment, setComment] = useState("");
            const [selectedOvertimeCode, setSelectedOvertimeCode] = useState(
                availability.code.id
            );
            const [allowedOvertimeCodes, setAllowedOvertimeCodes] = useState(
                []
            );

            useEffect(() => {
                dispatch(
                    ValidationActions.fetchOvertimeCodesForValidationAction(
                        availability.id
                    )
                ).then((response) => {
                    let tempArray = [];
                    Object.values(response.response.entities.overtimeCodes).map(
                        (overtimeCode, key) => {
                            tempArray.push({
                                value: overtimeCode.id,
                                label: overtimeCode.description,
                            });
                        }
                    );
                    setAllowedOvertimeCodes(tempArray);
                });
            }, []);

            const handleCommentChange = (e) => {
                setComment(e.target.value);
            };

            return (
                <Fragment>
                    <tr className="overtime-line">
                        {!editMode ? (
                            <td className="overtime-label">
                                {availability.code.description}
                            </td>
                        ) : (
                            <td className="overtime-edit-label">
                                <Select
                                    className="overtime-edit-type"
                                    classNamePrefix="overtime-edit-type"
                                    name="overtime-code"
                                    value={
                                        allowedOvertimeCodes
                                            ? allowedOvertimeCodes.find(
                                                  (option) => {
                                                      return (
                                                          option.value ===
                                                          selectedOvertimeCode
                                                      );
                                                  }
                                              )
                                            : ""
                                    }
                                    isOptionSelected={(selOpt) => {
                                        return selOpt === selectedOvertimeCode;
                                    }}
                                    options={allowedOvertimeCodes}
                                    onChange={(option) =>
                                        setSelectedOvertimeCode(option.value)
                                    }
                                    placeholder="Chargement..."
                                />
                            </td>
                        )}

                        <td className="overtime-date">
                            {daysForOvertimeList(
                                availability,
                                availability.type
                            ).map((day, key) => {
                                let count = 0;
                                if (day.hours) {
                                    if (day.hours.length > 0) {
                                        count += day.hours.length;
                                    }
                                }
                                if (day.tickets > 0) {
                                    count += 1;
                                }
                                const emptyLines = [];
                                for (
                                    let index = 0;
                                    index < count - 1;
                                    index++
                                ) {
                                    emptyLines.push(<div>&nbsp;</div>);
                                }
                                return (
                                    <Fragment>
                                        <div key={key}>
                                            {moment
                                                .parseZone(day.date)
                                                .format("DD/MM/YYYY")}
                                        </div>
                                        {emptyLines}
                                    </Fragment>
                                );
                            })}
                        </td>
                        <td className="overtime-start-at">
                            {daysForOvertimeList(
                                availability,
                                availability.type
                            ).map((day, key) => {
                                if (day.hours) {
                                    if (day.hours.length > 0) {
                                        return day.hours.map((hour, key) => {
                                            if (hour.duration > 0) {
                                                return (
                                                    <div key={key}>
                                                        {hour.start}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div key={key}>&nbsp;</div>
                                                );
                                            }
                                        });
                                    }
                                } else {
                                    return <div key={key}>&nbsp;</div>;
                                }
                            })}
                        </td>
                        <td className="overtime-end-at">
                            {daysForOvertimeList(
                                availability,
                                availability.type
                            ).map((day, key) => {
                                if (day.hours) {
                                    if (day.hours.length > 0) {
                                        return day.hours.map((hour, key) => {
                                            if (hour.duration > 0) {
                                                return (
                                                    <div key={key}>
                                                        {hour.end}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div key={key}>&nbsp;</div>
                                                );
                                            }
                                        });
                                    }
                                } else {
                                    return <div key={key}>&nbsp;</div>;
                                }
                            })}
                        </td>
                        <td className="overtime-hour-amount">
                            {daysForOvertimeList(
                                availability,
                                availability.type
                            ).map((day, key) => {
                                if (day.hours) {
                                    if (day.hours.length > 0) {
                                        return day.hours.map((hour, key) => {
                                            if (hour.duration > 0) {
                                                return (
                                                    <div key={key}>
                                                        {durationInHours(
                                                            hour.duration
                                                        )}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div key={key}>&nbsp;</div>
                                                );
                                            }
                                        });
                                    }
                                } else {
                                    return <div key={key}>&nbsp;</div>;
                                }
                            })}
                        </td>
                        <td className="overtime-total-hour">
                            {availability.hourCount > 0 ? (
                                durationInHours(availability.hourCount)
                            ) : (
                                <div>&nbsp;</div>
                            )}
                        </td>
                        <td className="overtime-total-constraint">
                            {availability.constraintCount}
                        </td>
                        <td className="overtime-ticket-amount">
                            {daysForOvertimeList(
                                availability,
                                availability.type
                            ).map((day, key) => {
                                if (day.tickets > 0) {
                                    return <div key={key}>{day.tickets}</div>;
                                }

                                return <Fragment key={key}>&nbsp;</Fragment>;
                            })}
                        </td>
                        <td className="overtime-total-ticket">
                            {availability.ticketCount > 0 ? (
                                availability.ticketCount
                            ) : (
                                <Fragment>&nbsp;</Fragment>
                            )}
                        </td>
                        <td className="overtime-comment">
                            {availability.comment ? (
                                availability.comment
                            ) : (
                                <Fragment>&nbsp;</Fragment>
                            )}
                        </td>
                        {me.hasRole("ROLE_ACTIVITY_OVERTIME_EDIT_TYPE") && (
                            <td className="overtime-actions">
                                {!editMode ? (
                                    <i
                                        className="far edit-overtime-icon fa-edit"
                                        onClick={() => setEditMode(true)}
                                    ></i>
                                ) : (
                                    <div>
                                        <i
                                            className="fas fa-times close-edit-overtime"
                                            onClick={() => setEditMode(false)}
                                        ></i>
                                        <i
                                            className="fas fa-check-square validate-edit-overtime"
                                            onClick={() =>
                                                patchOvertime(
                                                    availability.id,
                                                    comment,
                                                    selectedOvertimeCode
                                                )
                                            }
                                        ></i>
                                    </div>
                                )}
                            </td>
                        )}
                    </tr>
                    {editMode ? (
                        <div>
                            <div>Commentaire valideur (interne)</div>
                            <textarea
                                className="overtime-validation-comment"
                                onChange={(e) => handleCommentChange(e)}
                            ></textarea>
                        </div>
                    ) : (
                        ""
                    )}
                </Fragment>
            );
        };

        return (
            <div className="availability-container">
                <div className="validate-overtime-container-title">
                    Disponibilité
                </div>
                <table className="overtime-table">
                    <thead className="overtime-table-header">
                        <tr>
                            <td className="header header-label">Libellé</td>
                            <td className="header header-date">Date</td>
                            <td className="header header-start-at">H début</td>
                            <td className="header header-end-at">H fin</td>
                            <td className="header header-hour-amount">Nb h.</td>
                            <td className="header header-total-hour">
                                Total h.
                            </td>
                            <td className="header header-total-constraint">
                                Total ast
                            </td>
                            <td className="header header-ticket-amount">
                                Nb tickets
                            </td>
                            <td className="header header-total-ticket">
                                Total tickets
                            </td>
                            <td className="header header-comment availability-header">
                                Commentaire
                            </td>
                            {me.hasRole("ROLE_ACTIVITY_OVERTIME_EDIT_TYPE") && (
                                <td className="header header-actions">
                                    Actions
                                </td>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {availabilities.map((element, key) => {
                            return (
                                <AvailabilityLine
                                    key={key}
                                    availability={element}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const InterventionContainer = (props) => {
        const { interventions } = props;

        const InterventionLine = (props) => {
            const { intervention } = props;
            const [editMode, setEditMode] = useState(false);
            const [comment, setComment] = useState("");
            const [selectedOvertimeCode, setSelectedOvertimeCode] = useState(
                intervention.code.id
            );
            const [allowedOvertimeCodes, setAllowedOvertimeCodes] = useState(
                []
            );

            const handleCommentChange = (e) => {
                setComment(e.target.value);
            };

            useEffect(() => {
                dispatch(
                    ValidationActions.fetchOvertimeCodesForValidationAction(
                        intervention.id
                    )
                ).then((response) => {
                    let tempArray = [];
                    Object.values(response.response.entities.overtimeCodes).map(
                        (overtimeCode, key) => {
                            tempArray.push({
                                value: overtimeCode.id,
                                label: overtimeCode.description,
                            });
                        }
                    );
                    setAllowedOvertimeCodes(tempArray);
                });
            }, []);

            return (
                <Fragment>
                    <tr className="overtime-line">
                        {!editMode ? (
                            <td className="overtime-label">
                                {intervention.code.description}
                            </td>
                        ) : (
                            <td className="overtime-edit-label">
                                <Select
                                    className="overtime-edit-type"
                                    classNamePrefix="overtime-edit-type"
                                    name="overtime-code"
                                    value={
                                        allowedOvertimeCodes
                                            ? allowedOvertimeCodes.find(
                                                  (option) => {
                                                      return (
                                                          option.value ===
                                                          selectedOvertimeCode
                                                      );
                                                  }
                                              )
                                            : ""
                                    }
                                    isOptionSelected={(selOpt) => {
                                        return selOpt === selectedOvertimeCode;
                                    }}
                                    options={allowedOvertimeCodes}
                                    onChange={(option) =>
                                        setSelectedOvertimeCode(option.value)
                                    }
                                    placeholder="Chargement..."
                                />
                            </td>
                        )}

                        <td className="overtime-date">
                            {intervention.days.map((day, key) => {
                                return (
                                    <div key={key}>
                                        {moment
                                            .parseZone(day.date)
                                            .format("DD/MM/YYYY")}
                                    </div>
                                );
                            })}
                        </td>
                        <td className="overtime-start-at">
                            {intervention.days.map((day, key) => {
                                return day.hours.map((hour, key) => {
                                    return <div key={key}>{hour.start}</div>;
                                });
                            })}
                        </td>
                        <td className="overtime-end-at">
                            {intervention.days.map((day, key) => {
                                return day.hours.map((hour, key) => {
                                    return <div key={key}>{hour.end}</div>;
                                });
                            })}
                        </td>
                        <td className="overtime-hour-amount">
                            {intervention.days.map((day, key) => {
                                return day.hours.map((hour, key) => {
                                    return (
                                        <div key={key}>
                                            {durationInHours(hour.duration)}
                                        </div>
                                    );
                                });
                            })}
                        </td>
                        <td className="overtime-total-hour">
                            {durationInHours(intervention.hourCount)}
                        </td>
                        <td className="overtime-comment">
                            {intervention.comment ? (
                                intervention.comment
                            ) : (
                                <div>&nbsp;</div>
                            )}
                        </td>
                        {me.hasRole("ROLE_ACTIVITY_OVERTIME_EDIT_TYPE") && (
                            <td className="overtime-actions">
                                {!editMode ? (
                                    <i
                                        className="far fa-edit edit-overtime-icon"
                                        onClick={() => setEditMode(true)}
                                    ></i>
                                ) : (
                                    <Fragment>
                                        <i
                                            className="fas close-edit-overtime fa-times"
                                            onClick={() => setEditMode(false)}
                                        ></i>
                                        <i
                                            className="fas validate-edit-overtime fa-check-square"
                                            onClick={() =>
                                                patchOvertime(
                                                    intervention.id,
                                                    comment,
                                                    selectedOvertimeCode
                                                )
                                            }
                                        ></i>
                                    </Fragment>
                                )}
                            </td>
                        )}
                    </tr>
                    {editMode ? (
                        <div>
                            <div>Commentaire valideur (interne)</div>
                            <textarea
                                className="overtime-validation-comment"
                                onChange={(e) => handleCommentChange(e)}
                            ></textarea>
                        </div>
                    ) : (
                        ""
                    )}
                </Fragment>
            );
        };

        return (
            <div className="intervention-container">
                <div className="validate-overtime-container-title">
                    Intervention
                </div>
                <table className="overtime-table">
                    <thead className="overtime-table-header">
                        <tr>
                            <td className="header header-label">Libellé</td>
                            <td className="header header-date">Date</td>
                            <td className="header header-start-at">H début</td>
                            <td className="header header-end-at">H fin</td>
                            <td className="header header-hour-amount">Nb h.</td>
                            <td className="header header-total-hour">
                                Total h.
                            </td>
                            <td className="header header-comment intervention-header">
                                Commentaire
                            </td>
                            {me.hasRole("ROLE_ACTIVITY_OVERTIME_EDIT_TYPE") && (
                                <td className="header header-actions">
                                    Actions
                                </td>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {interventions.map((element, key) => {
                            return (
                                <InterventionLine
                                    key={key}
                                    intervention={element}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <Lightbox className="lb-centered validate-overtime-lb">
            <div className="validate-overtime-header">
                <div className="validate-overtime-title">Détail des HNO</div>
                <div className="close-lb" onClick={close}>
                    <i className="fal fa-times"></i>
                </div>
                <div className="validate-overtime-identity-block">
                    <div className="validate-overtime-identity">
                        {user.identity}
                    </div>
                    <PersonTypeBadge personType={user.type} />
                    <div className="validate-overtime-matricule">
                        {user.matricule}
                    </div>
                    <div className="validate-overtime-manager">
                        Responsable : {manager && manager.identity}
                    </div>
                </div>
            </div>

            <div className="all-overtime-container">
                {availabilities.length > 0 && (
                    <AvailabilityContainer availabilities={availabilities} />
                )}
                {interventions.length > 0 && (
                    <InterventionContainer interventions={interventions} />
                )}
            </div>
        </Lightbox>
    );
};

export default OvertimeDetails;
