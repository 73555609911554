class ContactItem {
    name;
    email;
    itemType;

    constructor(json, itemType) {
        this.itemType = itemType;
        this.name = json.name;
        this.email = json.email;
    }
}

export default ContactItem;
