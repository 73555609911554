import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import Dropzone from "react-dropzone";
import Slider from "components/TimesheetContainer/Calendar/Slider";
import CheckBox from "components/TimesheetContainer/Calendar/CheckBox";
import * as TimesheetActions from "actions/Timesheet";

import "./SpecificCRAContainer.scss";

const SpecificCRAContainer = (props) => {
    const dispatch = useDispatch();
    const {
        files,
        setFiles,
        disabled,
        setDisabled,
        certified,
        setCertified,
        special,
        setSpecial,
        afterSubmit,
        setTouched,
    } = props;

    useEffect(() => {
        if (files.length >= 3) {
            setDisabled(true);
        }
    }, []);

    const acceptedMimeTypes =
        "image/*, " +
        "text/csv, " +
        "text/plain, " +
        "application/pdf, " +
        "application/msword, " +
        "application/vnd.ms-excel, " +
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
        "application/vnd.openxmlformats-officedocument.presentationml.presentation, " +
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " +
        "application/vnd.oasis.opendocument.text, " +
        "application/vnd.oasis.opendocument.spreadsheet";

    const processFiles = (acceptedFiles) => {
        if (files.length + acceptedFiles.length > 3) {
            dispatch(TimesheetActions.notifyError("Trop de fichiers"));
        } else {
            let tempFiles = [];
            files.map((file) => tempFiles.push(file));
            acceptedFiles.map((file) => {
                const reader = new FileReader();

                new Promise((resolve, reject) => {
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                })
                    .then((result) => {
                        file.base64 = result.replace(/^data:.+;base64,/, "");
                        tempFiles.push(file);
                        setFiles([...tempFiles]);
                        setTouched(true);
                        setCertified(false);
                        if (tempFiles.length === 3) {
                            setDisabled(true);
                        }
                    })

                    .catch((error) => {
                        dispatch(TimesheetActions.notifyError(error.message));
                    });
            });
        }
    };

    const handleFileRemoval = (e, file) => {
        e.stopPropagation();
        e.preventDefault();
        if (afterSubmit && file.id) {
            // dispatch(setLoading(true));
            dispatch(TimesheetActions.deleteSpecificCra(file.id));
            // .then(() =>
            //     dispatch(setLoading(false))
            // );
        }
        let newFiles = files;
        for (let index = 0; index < newFiles.length; index++) {
            const element = newFiles[index];
            if (element == file) {
                newFiles.splice(index, 1);
            }
        }
        setFiles([...newFiles]);
        setDisabled(false);
        setCertified(false);
        setTouched(true);
    };

    return (
        <div className="specific-cra-container">
            {!afterSubmit && (
                <Slider
                    label="Déposer le CRA spécifique (CRA Client, CRA Client signé, CRA
                Synchrone signé)"
                    value={special}
                    onClick={() => setSpecial(!special)}
                />
            )}

            {(special || afterSubmit) && (
                <Fragment>
                    <Dropzone
                        onDrop={(acceptedFiles) => processFiles(acceptedFiles)}
                        accept={acceptedMimeTypes}
                        multiple={true}
                        maxSize={5242880}
                        disabled={disabled}
                    >
                        {({
                            getRootProps,
                            getInputProps,
                            acceptedFiles,
                            isDragActive,
                            isDragAccept,
                            isDragReject,
                        }) => (
                            <Fragment>
                                <div
                                    {...getRootProps({
                                        className: "files-container",
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    {files.map((file) => (
                                        <div
                                            className="dropped-file"
                                            key={
                                                file.path
                                                    ? file.path
                                                    : file.name
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            <div className="fal fa-file-download file-icon"></div>
                                            <div className="filename">
                                                {file.path
                                                    ? file.path
                                                    : file.name}
                                            </div>
                                            <div
                                                className="fal fa-times remove-file"
                                                title="Supprimer ce fichier"
                                                onClick={(e) =>
                                                    handleFileRemoval(e, file)
                                                }
                                            ></div>
                                        </div>
                                    ))}
                                    <div
                                        className={
                                            "dragzone" +
                                            (disabled ? " hidden" : "")
                                        }
                                    >
                                        Sélectionner un fichier
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </Dropzone>

                    <div className="warning-delete-specific-cra">
                        <i className="far fa-exclamation-triangle"></i> La
                        suppression d'un document s'effectue instantanément
                    </div>

                    <CheckBox
                        label="Je certifie que le CRA spécifique est conforme au CRA
                interne"
                        name="certified"
                        value={certified}
                        onClick={() => setCertified(!certified)}
                    />
                </Fragment>
            )}
        </div>
    );
};

export default SpecificCRAContainer;
