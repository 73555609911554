import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import * as TimesheetActions from "actions/Timesheet";
import "./Day.scss";

import {
    EntityRepository,
    HALFDAY_REPOSITORY,
    OVERTIME_REPOSITORY,
    OVERTIME_CODE_REPOSITORY,
} from "store/EntityRepository";

const Day = (props) => {
    const dispatch = useDispatch();
    const { day, selected, canOvertime, monthEntity, type, me, user } = props;
    const halfDayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);
    const overtimeRepo = EntityRepository().getRepository(OVERTIME_REPOSITORY);
    const overtimeCodeRepo = EntityRepository().getRepository(
        OVERTIME_CODE_REPOSITORY
    );
    const am = halfDayRepo.find(day.am);
    const pm = halfDayRepo.find(day.pm);
    const [hovered, setHovered] = useState(false);

    const overtimesForDay = overtimeRepo.findForDate(day.dateAt, type);
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const boundingEvents = useSelector(
        (state) => state.timesheet.entities.boundingEvents
    );
    const nextEvent = boundingEvents.next;
    const currentPeriod = useSelector(
        (state) => state.period.entities.periodLite?.currentPeriod
    );
    const today = currentPeriod
        ? currentPeriod.year + "-" + currentPeriod.month
        : moment.parseZone(day.dateAt).format("YYYY-MM");

    const getClassNames = () => {
        let classNames = "day ";

        if (selected || hovered || props.isInHoveredRange) {
            classNames += " selected";
        }

        if (day.workingState === "notCurrentMonth") {
            classNames += " not-current-month";
        }

        if (
            day.workingState !== "worked" ||
            (me &&
                ((me.startAt &&
                    !me.lastEndAt &&
                    moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                        moment.parseZone(me.startAt).format("YYYY-MM-DD")) ||
                    (me.endAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") >
                            moment.parseZone(me.endAt).format("YYYY-MM-DD")) ||
                    (me.lastEndAt &&
                        me.startAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") >
                            moment
                                .parseZone(me.lastEndAt)
                                .format("YYYY-MM-DD") &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                            moment
                                .parseZone(me.startAt)
                                .format("YYYY-MM-DD")))) ||
            (user &&
                ((user[0].startAt &&
                    moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                        moment
                            .parseZone(user[0].startAt)
                            .format("YYYY-MM-DD")) ||
                    (user[0].endAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") >
                            moment
                                .parseZone(user[0].endAt)
                                .format("YYYY-MM-DD"))))
        ) {
            classNames += " unavailable";
        }

        if (!selectable() || monthEntity.locked) {
            classNames += " not-selectable";
        }

        return classNames;
    };

    const selectable = () => {
        if (selectedStartDay) {
            if (
                moment.parseZone(day.dateAt).format("YYYY-MM-DD") >=
                    moment
                        .parseZone(selectedStartDay.day.dateAt)
                        .format("YYYY-MM-DD") &&
                moment.parseZone(day.dateAt).month() ===
                    moment.parseZone(selectedStartDay.day.dateAt).month()
            ) {
                if (
                    !nextEvent ||
                    (canOvertime &&
                        formattedMonth(monthEntity.month, monthEntity.year) <=
                            today)
                ) {
                    return true;
                } else {
                    if (
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                        moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD")
                    ) {
                        return true;
                    } else if (
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") ===
                        moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD")
                    ) {
                        if (nextEvent.startMoment === "pm") {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        return true;
    };

    const formattedMonth = (month, year) => {
        let monthString = "";
        if (month <= 10) {
            monthString = `0${month}`;
        } else {
            monthString = month;
        }

        return monthString + "-" + year;
    };

    const selectDay = (e) => {
        if (type !== "view" && day.workingState !== "notCurrentMonth") {
            if (monthEntity.editable) {
                let absenceOnly = false;
                if (
                    formattedMonth(monthEntity.month, monthEntity.year) > today
                ) {
                    absenceOnly = true;
                }

                if (!absenceOnly || (absenceOnly && me.type !== "contractor")) {
                    if (
                        (day.workingState === "worked" &&
                            (!me.endAt ||
                                (me.endAt &&
                                    moment
                                        .parseZone(day.dateAt)
                                        .format("YYYY-MM-DD") <=
                                        moment
                                            .parseZone(me.endAt)
                                            .format("YYYY-MM-DD"))) &&
                            moment.parseZone(day.dateAt).format("YYYY-MM-DD") >=
                                moment
                                    .parseZone(me.startAt)
                                    .format("YYYY-MM-DD") &&
                            (am === null || pm === null)) ||
                        (canOvertime && !absenceOnly) ||
                        monthEntity.specialTime
                    ) {
                        if (selectedStartDay === null) {
                            props.daySelect(day, "start");
                            dispatch(
                                TimesheetActions.checkEventBoundaries(
                                    moment
                                        .parseZone(day.dateAt)
                                        .format("YYYY-MM-DD")
                                )
                            );
                        } else {
                            if (
                                selectedStartDay.day.dateAt <= day.dateAt &&
                                selectable()
                            ) {
                                props.daySelect(day, "end");
                            }
                        }
                    }
                }
            }
        }
    };

    const hoverDay = (isHovered) => {
        if (type !== "view") {
            if (selectedStartDay) {
                if (
                    !props.isMonthSubmitted &&
                    (day.workingState === "worked" ||
                        (canOvertime &&
                            formattedMonth(
                                monthEntity.month,
                                monthEntity.year
                            ) <= today)) &&
                    selectedStartDay.day.dateAt <= day.dateAt
                ) {
                    if (isHovered) {
                        if (selectable(day)) {
                            props.dayHover(props.day);
                        }
                    } else {
                        dispatch(TimesheetActions.clearDaysHoveredSelection());
                    }
                }
            }
        }
    };

    const availabilitiesCount = () => {
        let availabilities = overtimesForDay.filter((overtime) => {
            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                overtime.code,
                type
            );
            if (overtimeCode) {
                if (overtimeCode.category === "availability") {
                    return overtime;
                }
            }
        });

        let count = 0;
        availabilities.map((availability, index) => {
            return availability.days.map((day, index) => {
                return day.hours.map((hour, index) => {
                    if (availability.type === "hour") {
                        count++;
                    }
                });
            });
        });

        if (count === 0 && availabilities.length > 0) {
            return availabilities.length;
        }

        return count;
    };

    const interventionsCount = () => {
        let interventions = overtimesForDay.filter((overtime) => {
            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                overtime.code,
                type
            );
            if (overtimeCode) {
                if (overtimeCode.category === "intervention") {
                    return overtime;
                }
            }
        });

        let count = 0;
        interventions.map((intervention, index) => {
            return intervention.days.map((day, index) => {
                return day.hours.map((hour, index) => {
                    count++;
                });
            });
        });

        return count;
    };

    const amountInExceed = () => {
        let count = 0;
        let interventions = interventionsCount();
        let availabilities = availabilitiesCount();
        if (interventions > 1) {
            count += interventions - 1;
        }

        if (availabilities > 1) {
            count += availabilities - 1;
        }

        return count;
    };

    const handleExpand = (e) => {
        e.stopPropagation();
        dispatch(TimesheetActions.expandDay(day, e.pageX, e.pageY));
    };

    return (
        <div
            onClick={(e) => selectDay(e)}
            className={getClassNames()}
            onMouseEnter={() => hoverDay(true)}
            onMouseLeave={() => hoverDay(false)}
        >
            {day.DayNumber}
            {(interventionsCount() > 1 || availabilitiesCount() > 1) && (
                <div
                    onClick={(e) => handleExpand(e)}
                    className="see-more-overtime"
                    title="Détails"
                >
                    <i className="fal fa-chevron-double-down more-overtime-icon"></i>
                </div>
            )}
        </div>
    );
};

export default Day;
