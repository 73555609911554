import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";

import "./EventWidget.scss";

const EventWidget = (props) => {
    const post = props.post;

    return (
        <div className="container-event-article">
            <div className="event-article-front">
                <div className="event-article-header">
                    <i className="fa fa-fw fa-calendar"></i> Agenda
                </div>
                <div className="event-article-title">{post.title}</div>
                {post.homepageImageUrl && (
                    <img src={post.homepagePicture} alt={post.title} />
                )}
                <div className="event-article-date">
                    <i className="far fa-fw fa-calendar"></i>{" "}
                    {moment.parseZone(post.dateAt).format("D/MM/YYYY")}
                </div>
                <div className="event-article-date">
                    <i className="far fa-fw fa-flip-horizontal fa-clock"></i>{" "}
                    {moment.parseZone(post.dateAt).format("H[h]mm")}
                </div>
                <div className="event-article-date">
                    <i className="fa fa-fw fa-map-marker"></i> {post.place}
                </div>
            </div>
        </div>
    );
};

export default EventWidget;
