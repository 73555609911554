import { combineReducers } from "redux";

import { GET_POSTS_SUCCESS, GET_HIGHLIGHT_SUCCESS } from "actions/News";
import Post from "entities/Post";

export const entities = (state = { posts: {}, highlight: null }, action) => {
    switch (action.type) {
        case GET_POSTS_SUCCESS:
            let posts = [];
            if (action.response.entities.posts) {
                let postsArray = Object.values(action.response.entities.posts);
                postsArray.forEach((element) => {
                    posts.push(new Post(element));
                });
            }
            return {
                ...state,
                posts: posts,
            };
        case GET_HIGHLIGHT_SUCCESS:
            let post, postEntity;
            if (action.response.entities.highlight) {
                post = Object.values(action.response.entities.highlight);
                postEntity = new Post(post[0]);
            }
            return {
                ...state,
                highlight: postEntity,
            };
        default:
            return state;
    }
};

export default combineReducers({
    entities,
});
