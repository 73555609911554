import React, { useState } from "react";
import "./ExpandedDay.scss";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
    EntityRepository,
    OVERTIME_REPOSITORY,
    OVERTIME_CODE_REPOSITORY,
    EVENT_REPOSITORY,
} from "store/EntityRepository";
import * as TimesheetActions from "actions/Timesheet";

import moment from "moment";
import "moment/locale/fr";
import usePressEscapeKey from "hooks/usePressEscapeKey";
import useClickOutside from "hooks/useClickOutside";
import Intervention from "components/TimesheetContainer/Calendar/Intervention";
import Availability from "components/TimesheetContainer/Calendar/Availability";
import Event from "components/TimesheetContainer/Calendar/Event";

const ExpandedDay = (props) => {
    const { type, isMonthEditable, currentMonth, currentYear } = props;
    const dispatch = useDispatch();
    const overtimeRepo = EntityRepository().getRepository(OVERTIME_REPOSITORY);
    const overtimeCodeRepo = EntityRepository().getRepository(
        OVERTIME_CODE_REPOSITORY
    );
    const eventRepo = EntityRepository().getRepository(EVENT_REPOSITORY);
    const expandedDay = useSelector(
        (state) => state.timesheet.entities.expandedDay
    );
    const hoveredOvertimeId = useSelector(
        (state) => state.timesheet.hoveredOvertime
    );
    const hoveredEventId = useSelector((state) => state.timesheet.hoveredEvent);

    const [events, setEvents] = useState([]);
    const [overtimes, setOvertimes] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [availabilities, setAvailabilities] = useState([]);
    const [componentPosX, setComponentPosX] = useState(-1000);
    const [componentPosY, setComponentPosY] = useState(-1000);

    const ref = useClickOutside(() => {
        if (expandedDay.id) {
            dispatch(TimesheetActions.expandDay());
        }
    });

    usePressEscapeKey(() => {
        if (expandedDay.id) {
            dispatch(TimesheetActions.expandDay());
        }
    });

    useEffect(() => {
        if (expandedDay.id) {
            if (expandedDay.posX) {
                setComponentPosX(expandedDay.posX - 100);
            } else {
                setTimeout(() => {
                    setComponentPosX(-1000);
                }, 501);
            }
            if (expandedDay.posY) {
                setComponentPosY(expandedDay.posY - 100);
            } else {
                setTimeout(() => {
                    setComponentPosY(-1000);
                }, 501);
            }
        } else {
            setComponentPosX(-1000);
            setComponentPosY(-1000);
        }
    }, [expandedDay.posX, expandedDay.posY]);

    useEffect(() => {
        setOvertimes(
            overtimeRepo.findForDateWithoutHook(expandedDay.dateAt, type)
        );
        setEvents(eventRepo.findForDateWithoutHook(expandedDay.dateAt, type));
    }, [expandedDay]);

    useEffect(() => {
        setInterventions(
            overtimes.filter((overtime) => {
                let overtimeCode = overtimeCodeRepo.findWithoutHook(
                    overtime.code,
                    type
                );
                if (overtimeCode) {
                    if (overtimeCode.category === "intervention") {
                        return overtime;
                    }
                }
                return false;
            })
        );
        setAvailabilities(
            overtimes.filter((overtime) => {
                let overtimeCode = overtimeCodeRepo.findWithoutHook(
                    overtime.code,
                    type
                );
                if (overtimeCode) {
                    if (overtimeCode.category === "availability") {
                        return overtime;
                    }
                }
                return false;
            })
        );
    }, [overtimes]);

    return (
        <div
            style={{
                left: componentPosX,
                top: componentPosY,
            }}
            className={
                expandedDay.id
                    ? "visible-expanded-display"
                    : "hidden-expanded-display"
            }
            id="expanded-day"
            ref={ref}
        >
            {expandedDay.id && (
                <div className="day-number">
                    {moment.parseZone(expandedDay.dateAt).format("D")}
                </div>
            )}
            {events.map((event, key) => {
                return (
                    <Event
                        key={key}
                        entity={event}
                        expandedDisplay={true}
                        hovered={hoveredEventId === event.id ? true : false}
                        multiple={events.length > 1}
                        type={type}
                    />
                );
            })}
            {availabilities.map((availability, key) => {
                return (
                    <Availability
                        key={key}
                        expandedDisplay={true}
                        entity={availability}
                        isMonthEditable={isMonthEditable}
                        currentMonth={currentMonth}
                        currentYear={currentYear}
                        type={type}
                    />
                );
            })}
            {interventions.map((intervention, key) => {
                let interventionCode = overtimeCodeRepo.findWithoutHook(
                    intervention.code,
                    type
                );
                let relevantDay = intervention.days.filter((day, index) => {
                    return (
                        day.date ===
                        moment
                            .parseZone(expandedDay.dateAt)
                            .format("YYYY-MM-DD")
                    );
                })[0];
                if (relevantDay) {
                    return relevantDay.hours.map((hour, key) => {
                        hour.description = interventionCode
                            ? interventionCode.description
                            : "";
                        return (
                            <Intervention
                                key={key}
                                entity={hour}
                                isMonthEditable={isMonthEditable}
                                expandedDisplay={true}
                                currentMonth={currentMonth}
                                currentYear={currentYear}
                                type={type}
                                hovered={
                                    hoveredOvertimeId === intervention.id
                                        ? true
                                        : false
                                }
                            />
                        );
                    });
                }
            })}
        </div>
    );
};

export default ExpandedDay;
