import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/fr";

import {
    EntityRepository,
    EVENT_CODE_REPOSITORY,
    EVENT_REPOSITORY,
    MONTH_REPOSITORY,
} from "store/EntityRepository";
import * as TimesheetActions from "actions/Timesheet";
import Edit from "components/TimesheetContainer/Forms/Event/Edit";

import { changeOverlayContent } from "events/OverlayEvents";

import "./Event.scss";

const Event = (props) => {
    const dispatch = useDispatch();

    const ABSENCE_TRANSPARENT_COLOR = "#D288B0";
    const ACTIVITY_TRANSPARENT_COLOR = "#92DCEB";
    const HOURS_NOT_WORKED_TRANSPARENT_COLOR = "#FEDE99";

    const {
        entity,
        currentMonth,
        firstDayOfMonth,
        type,
        expandedDisplay,
        multiple,
    } = props;

    const monthRepo = EntityRepository().getRepository(MONTH_REPOSITORY);
    const eventRepo = EntityRepository().getRepository(EVENT_REPOSITORY);
    const eventCodeRepo = EntityRepository().getRepository(
        EVENT_CODE_REPOSITORY
    );
    const eventCode = eventCodeRepo.find(entity.eventCode, type);
    const realEvent = eventRepo.find(entity.id, type);
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );

    const getStartDateForMonth = () => {
        let startAtForThisMonth = moment.parseZone(entity.startAt);
        while (startAtForThisMonth.month() + 1 < currentMonth) {
            startAtForThisMonth.add(1, "days");
        }

        return startAtForThisMonth;
    };

    const getEndDateForMonth = () => {
        let endAtForThisMonth = moment.parseZone(entity.endAt);
        while (endAtForThisMonth.month() + 1 > currentMonth) {
            endAtForThisMonth.subtract(1, "days");
        }

        return endAtForThisMonth;
    };

    const startAt = getStartDateForMonth();
    const endAt = getEndDateForMonth();

    const hoverEvent = (isHovered) => {
        if (type !== "view") {
            if (entity && !selectedStartDay) {
                if (
                    entity.id &&
                    // entity.state !== "validated1" &&
                    // entity.state !== "validated2" &&
                    entity.editable === true
                ) {
                    if (isHovered) {
                        dispatch(TimesheetActions.hoverEvent(entity.id));
                    } else {
                        dispatch(TimesheetActions.hoverEvent(null));
                    }
                }
            }
        }
    };

    const handleClick = () => {
        if (type !== "view") {
            if (realEvent) {
                editEvent();
            }
        }
    };

    const editEvent = () => {
        if (
            !props.isMonthSubmitted &&
            !selectedStartDay &&
            // realEvent.state === "submitted" &&
            realEvent.editable === true
        ) {
            if (props.entity) {
                dispatch(TimesheetActions.checkEventBoundaries(entity.id));
                changeOverlayContent(
                    <Edit
                        action="edit"
                        targetEvent={realEvent}
                        autofillable={props.autofillable}
                        mainActivity={props.mainActivity}
                        currentMonth={currentMonth}
                        monthEntity={monthRepo.findCurrentMonth()}
                    />
                );
            }
        }
    };

    const getEventWidth = () => {
        if (expandedDisplay) {
            if (multiple) {
                return "90px";
            }
            return "180px";
        }

        let width =
            (1 + endAt.weekday() - startAt.weekday()) * 169 +
            (endAt.weekday() - startAt.weekday()) * 2;

        if (props.entity.startMoment === "pm") {
            width -= 169 / 2;
        }

        if (props.entity.endMoment === "am") {
            width -= 169 / 2;
        }

        return width + "px";
    };

    const getLeftPosition = () => {
        if (expandedDisplay) {
            return "initial";
        }

        return (
            startAt.weekday() * 171 +
            2 +
            (props.entity.startMoment === "pm" ? 169 / 2 : 0) +
            "px"
        );
    };

    const getTopPosition = () => {
        if (expandedDisplay) {
            return "initial";
        }

        return (
            Math.ceil((startAt.date() + firstDayOfMonth.weekday()) / 7 - 1) *
                122 +
            33 +
            "px"
        );
    };

    const styles = {
        border: "2px solid",
        borderColor: eventCode ? eventCode.color : "transparent",
        position: !expandedDisplay ? "absolute" : "initial",
        left: getLeftPosition(),
        width: getEventWidth(),
        top: getTopPosition(),
        display: expandedDisplay ? "inline-block" : "block",
    };

    if (props.hovered) {
        styles.color = "white";
        styles.borderColor = "transparent";
        let backgroundColor;
        if (eventCode) {
            if (eventCode.type === "absence") {
                backgroundColor = ABSENCE_TRANSPARENT_COLOR;
            } else if (eventCode.type === "activity") {
                backgroundColor = ACTIVITY_TRANSPARENT_COLOR;
            } else if (eventCode.type === "hoursNotWorked") {
                backgroundColor = HOURS_NOT_WORKED_TRANSPARENT_COLOR;
            }
        }
        styles.backgroundColor = backgroundColor;
    }

    if (entity.autofilled) {
        styles.borderColor = "#d4d4d4";
        styles.pointerEvents = "none";
    }

    if (entity.state === "validated1" || entity.state === "validated2") {
        styles.color = "white";
        styles.cursor = "default";
        if (eventCode) {
            styles.backgroundColor = eventCode.color;
        }
    }

    if (selectedStartDay) {
        styles.cursor = "default";
    }

    const getEventDescription = () => {
        if (eventCode) {
            return (
                <Fragment>
                    {entity.remoteWorking && (
                        <i
                            title="Télétravail"
                            className="fas fa-home-lg-alt"
                        ></i>
                    )}{" "}
                    {eventCode.description}
                </Fragment>
            );
        } else if (entity.autofilled && props.mainActivity) {
            return props.mainActivity.description;
        }

        return "";
    };

    return (
        <div
            title={eventCode && eventCode.description}
            className={
                "event" +
                (eventCode &&
                (eventCode.type === "sickness" ||
                    eventCode.type === "maternity")
                    ? " sick-event"
                    : "") +
                (type === "view" ? " view-only" : "")
            }
            style={styles}
            onMouseEnter={() => hoverEvent(true)}
            onMouseLeave={() => hoverEvent(false)}
            onClick={() => handleClick()}
        >
            <div className="description">{getEventDescription()}</div>
            {eventCode &&
            (eventCode.type === "sickness" ||
                eventCode.type === "maternity") ? (
                <div className="sick"></div>
            ) : (
                ""
            )}
        </div>
    );
};

export default Event;
